import { ToggleButton } from "@faro-lotv/flat-ui";
import { Stack, StackProps, ToggleButtonProps } from "@mui/material";
import { forwardRef } from "react";

export type EmbeddedToolbarProps = StackProps & {
  /** True to show and enable events */
  isActive: boolean;
  /** True if the toolbar should be stacked vertically */
  vertical?: boolean;
};

/** @returns a styled toolbar to be used embedded in a 3d scene */
export function EmbeddedToolbar({
  isActive,
  vertical,
  children,
  sx,
  ...rest
}: EmbeddedToolbarProps): JSX.Element {
  const pointerEvents = isActive ? "auto" : "none";

  return (
    <Stack
      component="div"
      justifyContent="space-between"
      direction={vertical ? "column" : "row"}
      alignItems="center"
      sx={[
        {
          pointerEvents,
          opacity: isActive ? 1 : 0,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {children}
    </Stack>
  );
}

export type EmbeddedToolbarButton = ToggleButtonProps & {
  /**
   * Size of the button as a valid css size
   *
   * @default 36px
   */
  buttonSize?: string;
};

/** @returns a small button to use in an EmbeddedToolbar */
export const EmbeddedToolbarButton = forwardRef<
  HTMLButtonElement,
  EmbeddedToolbarButton
>(function EmbeddedToolbarButton(
  { children, buttonSize = "36px", ...rest },
  ref,
): JSX.Element {
  return (
    <ToggleButton
      ref={ref}
      sx={{
        height: buttonSize,
        width: buttonSize,
        border: 0,
        padding: 0,
        borderRadius: 0,
        backdropFilter: null,
        ":first-child": {
          borderRadius: "6px 0 0 6px",
        },
        ":last-child": {
          borderRadius: "0 6px 6px 0",
        },
      }}
      {...rest}
      neverPropagateOnClick
    >
      {children}
    </ToggleButton>
  );
});
