import { PointCloudAnalysis } from "@/store/point-cloud-analysis-tool-slice";
import { selectAnnotationSection } from "@/store/selections-selectors";
import { RootState } from "@/store/store";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { assert } from "@faro-lotv/foundation";
import { IElementSection, isAnnotationGroup } from "@faro-lotv/ielement-types";
import {
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import { DEFAULT_NODES_GROUP_SCALE_UNIFORM } from "@faro-lotv/service-wires";
import { Matrix4, Quaternion, Vector3 } from "three";

/**
 * The point cloud color map analysis setting that will be saved in json file
 *
 * The difference between ColorMapAnalysisData and PointCloudAnalysis is as follows:
 * 1. id in PointCloudAnalysis will be used as analysis IElement id, no need to save it in json file
 * 2. everything in PointCloudAnalysis is in world coordinate system while in ColorMapAnalysisData,
 *    they are all in local coordinate relative to analysis IElement
 */
type ColorMapAnalysisData = Omit<PointCloudAnalysis, "id">;

/**
 * convert given PointCloudAnalysis into ColorMapAnalysisData and create the json file
 *
 * @param appState The app state
 * @param analysis the given PointCloudAnalysis object
 * @param area The current area of the project
 * @returns json file of the color map analysis data
 */
export function createAnalysisJsonFile(
  appState: RootState,
  analysis: PointCloudAnalysis,
  area: IElementSection,
): File {
  const worldTransform = selectAnalysisWorldMatrix(analysis, area)(appState);

  const colorMapAnalysisData = transformPointCloudAnalysisObject(
    analysis,
    worldTransform,
  );

  const jsonData = JSON.stringify(colorMapAnalysisData);

  return new File([jsonData], "analysis.json", {
    type: "text/plain",
  });
}

function transformPointCloudAnalysisObject(
  analysis: PointCloudAnalysis,
  worldTransform: Matrix4,
): ColorMapAnalysisData {
  const matrix = worldTransform.clone().invert();

  const TEMP_VEC1 = new Vector3();
  const TEMP_VEC2 = new Vector3();
  const rotation = new Quaternion();
  matrix.decompose(TEMP_VEC1, rotation, TEMP_VEC2);

  const localPolygon = analysis.polygonSelection.map((p) =>
    TEMP_VEC1.fromArray(p).applyMatrix4(matrix).toArray(),
  );

  const localCameraDirection = analysis.cameraWorldDirection
    ? TEMP_VEC1.fromArray(analysis.cameraWorldDirection)
        .applyQuaternion(rotation)
        .toArray()
    : undefined;

  return {
    polygonSelection: localPolygon,
    tolerance: analysis.tolerance,
    parentId: analysis.parentId,
    referencePlaneType: analysis.referencePlaneType,
    isVisible: analysis.isVisible,
    elevation: analysis.elevation,
    showReferencePlane: analysis.showReferencePlane,
    cameraWorldDirection: localCameraDirection,
  };
}

/**
 * Get the world matrix of the given point cloud analysis IElement
 *
 * @param analysis the given PointCloudAnalysis object
 * @param area The current area of the project
 * @returns The world transform as Matrix4
 */
function selectAnalysisWorldMatrix(
  analysis: PointCloudAnalysis,
  area: IElementSection,
) {
  return (appState: RootState): Matrix4 => {
    const analysisElement = selectIElement(analysis.id)(appState);
    if (analysisElement) {
      return selectIElementWorldMatrix4(analysis.id)(appState);
    }

    const cloudElement = selectIElement(analysis.parentId)(appState);
    assert(
      cloudElement,
      "There must be point cloud associated to the point cloud analysis",
    );

    // analysis will be saved under annotation section
    const annotationParent = selectAnnotationSection(
      cloudElement,
      area,
    )(appState);
    assert(annotationParent, "There must be annotation section");

    // Check if the section already contains an annotation group
    const annotationGroup = selectChildDepthFirst(
      annotationParent,
      isAnnotationGroup,
      1,
    )(appState);

    const worldTransform = selectIElementWorldMatrix4(
      annotationGroup?.id ?? annotationParent.id,
    )(appState).clone();
    if (!annotationGroup) {
      worldTransform.scale(
        new Vector3(
          DEFAULT_NODES_GROUP_SCALE_UNIFORM,
          DEFAULT_NODES_GROUP_SCALE_UNIFORM,
          DEFAULT_NODES_GROUP_SCALE_UNIFORM,
        ),
      );
    }

    return worldTransform;
  };
}
