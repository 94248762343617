import {
  ElementIcon,
  ElementIconType,
  useElementIcon,
} from "@/components/ui/icons";
import { OverlayBadgeData, useOverlayBadge } from "@/hooks/use-overlay-bagde";
import { selectDefaultModeFor } from "@/modes";
import {
  selectActiveBackgroundTasksInChildren,
  selectBackgroundTaskForIElement,
} from "@/store/background-tasks/background-tasks-selector";
import { RootState } from "@/store/store";
import { useAppSelector } from "@/store/store-hooks";
import { selectCanReadPointCloud } from "@/store/subscriptions/subscriptions-selectors";
import { selectHasWritePermission } from "@/store/user-selectors";
import { BackgroundTask } from "@/utils/background-tasks";
import {
  CircularProgress,
  NO_TRANSLATE_CLASS,
  useCheckForOverflow,
} from "@faro-lotv/flat-ui";
import {
  GUID,
  IElementTypeHint,
  isIElementSection,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import {
  TreeData,
  selectIElement,
  selectIElementChildren,
  selectIsDataSessionEmpty,
  selectIsSubtreeLoading,
} from "@faro-lotv/project-source";
import { isBackgroundTaskActive } from "@faro-lotv/service-wires";
import { Badge, Box, Stack, Typography } from "@mui/material";
import { MouseEvent, useCallback, useRef, useState } from "react";
import { ContextMenu } from "../tree-context-menu";
import { TreeNode, TreeNodeProps } from "../tree-node";
import { TreePopUp } from "../tree-popup";
import { TreeNodeDisabledReason } from "../tree-types";
import { CaptureTreeAction } from "./capture-tree-action";

/**
 * Pick the current task for a specific tree node.
 *
 * Eg a task that references the tree node directly
 * or, if the tree node is a section a task relative to one
 * or, if the tree node is a laser scan section and one of the laser scans associated to it has a task
 *
 * @param id the IElement id for this tree node
 * @returns the Task to pair to this tree node
 */
function selectBackgroundTaskForTreeNode(id: GUID) {
  return (state: RootState): BackgroundTask | undefined => {
    const iElement = selectIElement(id)(state);
    if (iElement === undefined) return;

    const task = selectBackgroundTaskForIElement(id)(state);
    if (task) {
      return task;
    }

    if (isIElementSection(iElement)) {
      for (const id of iElement.childrenIds ?? []) {
        const task = selectBackgroundTaskForIElement(id)(state);
        if (task) {
          return task;
        }
      }
    }

    if (isIElementSectionDataSession(iElement)) {
      // There is no task associated to given node and it is a laser scan section,
      // then check if any of the child point clouds are being processed and take the first task
      return selectActiveBackgroundTasksInChildren(id)(state)[0];
    }
  };
}

/**
 * @returns a reason why a node is disabled or undefined otherwise
 * @param id the iElement id of the node
 */
function selectTreeNodeDisabledReason(id: GUID) {
  return (state: RootState): TreeNodeDisabledReason | undefined => {
    const element = selectIElement(id)(state);
    if (!element) return;

    if (isIElementSectionDataSession(element)) {
      const isPointCloudOnly = selectIElementChildren(element.id)(state).every(
        (el) => el.typeHint === IElementTypeHint.dataSetPCloudUpload,
      );

      // Check if the data session only contains a point cloud with no 2d data
      // and the company does not have the point cloud bundle
      // So that we can display a specific message
      const isMissingPCPermission =
        isPointCloudOnly && !selectCanReadPointCloud(state);

      if (isMissingPCPermission) {
        return TreeNodeDisabledReason.missingPointCloudPermission;
      }

      const isDataSessionEmpty = selectIsDataSessionEmpty(element)(state);

      // Disable data session if it's empty (no path, nor stream, nor floorplan)
      if (isDataSessionEmpty) {
        return TreeNodeDisabledReason.notSupported;
      }
    }

    // Check if the viewer can display the element
    if (selectDefaultModeFor(element)(state) === undefined) {
      return TreeNodeDisabledReason.notSupported;
    }
  };
}

/** @returns the component to use for each node in the capture tree. It contains an arrow button to expand the node and a label */
export function CaptureTreeNode({
  node,
  style,
}: TreeNodeProps<TreeData>): JSX.Element {
  const { hasOverflown, checkForOverflow } = useCheckForOverflow();

  const icon = useElementIcon(node.data.element, node.data.directParent);

  const task = useAppSelector(selectBackgroundTaskForTreeNode(node.id));
  const taskInProgress = !!task && isBackgroundTaskActive(task.state);

  const overlayBadgeData = useOverlayBadge(node.id, node.isClosed);

  const disabledReason = useAppSelector(selectTreeNodeDisabledReason(node.id));
  const isDisabled = !!disabledReason;

  // Since react-arborist has no built-in "disabled" state this callback prevents items from being selected
  const onNodeClick = useCallback(
    (ev: MouseEvent) => {
      if (isDisabled) {
        ev.stopPropagation();
      }
    },
    [isDisabled],
  );

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const showTooltip = useCallback(() => setIsPopupOpen(true), []);
  const hideTooltip = useCallback(() => setIsPopupOpen(false), []);

  const onToggleContextMenu = useCallback(
    (open: boolean) => {
      if (isPopupOpen && open) {
        hideTooltip();
      }
    },
    [isPopupOpen, hideTooltip],
  );

  const boxRef = useRef<HTMLDivElement>();

  return (
    <Box
      ref={boxRef}
      component="div"
      style={{ ...style, height: "100%" }}
      onClick={onNodeClick}
      onMouseOver={showTooltip}
      onMouseOut={hideTooltip}
    >
      <TreePopUp
        parentRef={boxRef}
        title={node.data.label}
        isTooltipEnabled={hasOverflown || isDisabled || taskInProgress}
        disabledReason={disabledReason}
        task={task}
        open={isPopupOpen}
      >
        <TreeNode<TreeData> node={node}>
          <NodeLabel
            iconType={icon}
            overlayBadgeData={overlayBadgeData}
            name={node.data.label}
            element={node.data.element}
            onMouseEnterName={(ev) => checkForOverflow(ev.currentTarget)}
            activeTask={taskInProgress ? task : undefined}
            onToggleContextMenu={onToggleContextMenu}
            isDisabled={isDisabled}
          />
        </TreeNode>
      </TreePopUp>
    </Box>
  );
}

type NodeLabelProps = {
  /** Specific Icon to show for the current tree node */
  iconType: ElementIconType;

  /** Type of this IElement */
  element?: TreeData["element"];

  /** Name of the IElement represented by the node */
  name: TreeData["label"];

  /** Contains data necessary to render the overlay icon on the node */
  overlayBadgeData?: OverlayBadgeData;

  /** Method to call when the mouse enters the element containing the name */
  onMouseEnterName(event: React.MouseEvent): void;

  /** a task which is associated to current node and is active or in progress */
  activeTask?: BackgroundTask;

  /** Callback executed when the context menu state changes between open and close */
  onToggleContextMenu(open: boolean): void;

  /** True if the node is disabled */
  isDisabled: boolean;
};

/** @returns the label component used, for each node, in the tree with the node's icon, name and context menu */
function NodeLabel({
  iconType,
  name,
  element,
  overlayBadgeData,
  onMouseEnterName,
  activeTask,
  onToggleContextMenu,
  isDisabled,
}: NodeLabelProps): JSX.Element {
  // If the user has permission to edit the or not
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  const isLoading = useAppSelector((state) =>
    element ? selectIsSubtreeLoading(element.id)(state) : false,
  );

  return (
    <>
      {/* FIXME: Figure out how to handle PC alignment state without querying the store here */}
      <Badge
        badgeContent={overlayBadgeData?.badgeContent}
        color={
          overlayBadgeData ? overlayBadgeData.badgeBackgroundColor : "warning"
        }
        overlap="circular"
        invisible={!overlayBadgeData}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {isLoading ? (
          <CircularProgress size="1.125em" />
        ) : (
          <ElementIcon icon={iconType} sx={{ fontSize: "1.125em" }} />
        )}
      </Badge>

      <Typography
        aria-label={name}
        ml={1}
        fontSize="0.875em"
        flexGrow={1}
        noWrap
        onMouseEnter={onMouseEnterName}
        className={NO_TRANSLATE_CLASS}
      >
        {name}
      </Typography>
      <Stack direction="row" alignItems="center" rowGap={0.75} height="100%">
        {!isDisabled && element && (
          <CaptureTreeAction
            id={element.id}
            type={element.type}
            typeHint={element.typeHint}
          />
        )}
        {activeTask && (
          <CircularProgress
            size={16}
            sx={{ minHeight: "16px", minWidth: "16px", mx: 1 }}
            variant={activeTask.progress > 0 ? "determinate" : "indeterminate"}
            value={activeTask.progress}
          />
        )}
      </Stack>
      {element && hasWritePermission && (
        <ContextMenu
          id={element.id}
          isNodeDisabled={isDisabled}
          onToggle={onToggleContextMenu}
        />
      )}
    </>
  );
}
