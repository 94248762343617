import {
  PointCloudAnalysis,
  setAnalysisColormap,
  setAnalysisTolerance,
} from "@/store/point-cloud-analysis-tool-slice";
import { useAppDispatch } from "@/store/store-hooks";
import {
  convertUnit,
  MeasurementUnits,
} from "@faro-lotv/app-component-toolbox";
import {
  ColorBar,
  ColorsWithRatio,
  Dropdown,
  FaroText,
  neutral,
  TextField,
} from "@faro-lotv/flat-ui";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { areColormapsSame, Colormap, ColormapPoints } from "@faro-lotv/lotv";
import { Box, Grid, Stack } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Color, ColorManagement } from "three";

/**
 * Enum defines list of presets of colormaps which can be used for point clouds analysis
 */
export enum PointCloudAnalysisColormapPreset {
  rainbow = "rainbow",
  grayscale = "grayscale",
  blueGreenRed = "blueGreenRed",
  redGreenRed = "redGreenRed",
}

export type PointCloudAnalysisColormapPresetKey =
  keyof typeof PointCloudAnalysisColormapPreset;

/**
 * @param name The name to check
 * @returns True if the given name string is a colormap preset name
 */
function isColormapPresetName(
  name: string,
): name is PointCloudAnalysisColormapPresetKey {
  return name in PointCloudAnalysisColormapPreset;
}

/**
 * List of pre-defined colormap colors.
 *
 * Note:
 * - Two consecutive equal values indicates a color jump at this value.
 * - values go from 0.0 (minimum range) to 1.0 (maximum range)
 * - value 0.5 is 0.0 deviation IF minium and maximum absolute deviations are equal
 */
export const colormapPresets: Record<
  PointCloudAnalysisColormapPresetKey,
  Colormap
> = {
  rainbow: [
    { value: 0.0, color: new Color("#FF00FF") },
    { value: 0.0, color: new Color("#0000FF") },
    { value: 0.25, color: new Color("#00FFFF") },
    { value: 0.5, color: new Color("#00FF00") },
    { value: 0.75, color: new Color("#FFFF00") },
    { value: 1.0, color: new Color("#FF5400") },
    { value: 1.0, color: new Color("#FF0000") },
  ],
  grayscale: [
    { value: 0.0, color: new Color("#15191F") },
    { value: 1.0, color: new Color("#F8F9FA") },
  ],
  blueGreenRed: [
    { value: 0.0, color: new Color("#0000FF") },
    { value: 0.0, color: new Color("#00FF00") },
    { value: 1.0, color: new Color("#00FF00") },
    { value: 1.0, color: new Color("#FF0000") },
  ],
  redGreenRed: [
    { value: 0.0, color: new Color("#FF0000") },
    { value: 0.0, color: new Color("#00FF00") },
    { value: 1.0, color: new Color("#00FF00") },
    { value: 1.0, color: new Color("#FF0000") },
  ],
};

const colormapOptions = [
  {
    key: PointCloudAnalysisColormapPreset.rainbow,
    label: "Rainbow",
    value: PointCloudAnalysisColormapPreset.rainbow,
  },
  {
    key: PointCloudAnalysisColormapPreset.grayscale,
    label: "Grayscale",
    value: PointCloudAnalysisColormapPreset.grayscale,
  },
  {
    key: PointCloudAnalysisColormapPreset.blueGreenRed,
    label: "Blue-green-red",
    value: PointCloudAnalysisColormapPreset.blueGreenRed,
  },
  {
    key: PointCloudAnalysisColormapPreset.redGreenRed,
    label: "Red-green-red",
    value: PointCloudAnalysisColormapPreset.redGreenRed,
  },
];

type ColormapOptionsProps = {
  /** Colormap points for the active analysis. */
  colormapPoints: ColormapPoints;

  /** Active analysis object to be modified. */
  analysis: PointCloudAnalysis;

  /** Current selected unit of measure used to display the distances */
  unitOfMeasure: SupportedUnitsOfMeasure;
};

/** @returns Colormap options UI panel */
export function ColormapOptionsPanel({
  colormapPoints,
  analysis,
  unitOfMeasure,
}: ColormapOptionsProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [colormapName, setColormapName] = useState(() => {
    for (const [key, value] of Object.entries(colormapPresets)) {
      if (areColormapsSame(value, colormapPoints.colormap)) {
        return key;
      }
    }
  });

  const computeColorBarColors = useCallback((): ColorsWithRatio => {
    const { colormap } = colormapPoints;

    // The whole colormap range is mapped to the middle portion of the color bar.
    // The space before and after the whole range is padding with the minimal and maximal deviation color.
    // This constant defines padding ratio of the space before and after the color map range.
    const COLOR_SCALE_PADDING = 0.15;
    // scale the colormap range to middle portion of the color bar, with padding on both sides
    const colorRange = 1.0 - 2 * COLOR_SCALE_PADDING;

    // Colors supplied to three.js need to be in the Linear-sRGB. Certain conversions (for hexadecimal
    // and CSS colors in sRGB) can be made automatically if the THREE.ColorManagement API is enabled.
    // see: https://threejs.org/docs/?q=color#manual/en/introduction/Color-management
    // Enable the ColorManagement make sure `getHexString` return the hexadecimal color in sRGB for CSS.
    // However, the global flag `ColorManagement.enabled` may be set to `false` by the application for
    // certain reason, so we need to save and restore it.
    const colorManagementEnabled = ColorManagement.enabled;
    ColorManagement.enabled = true;

    const colors: ColorsWithRatio = colormap.map((colorKey) => ({
      color: `#${colorKey.color.getHexString()}`,
      ratio: colorKey.value * colorRange + COLOR_SCALE_PADDING,
    }));

    ColorManagement.enabled = colorManagementEnabled;

    return [
      {
        color: colors[0].color,
        ratio: 0.0,
      },
      ...colors,
      {
        color: colors[colors.length - 1].color,
        ratio: 1.0,
      },
    ];
  }, [colormapPoints]);

  const [colorBarColors, setColorBarColors] = useState(computeColorBarColors);

  const changeColormap = useCallback(
    (newColormap: string) => {
      setColormapName(newColormap);

      let colormapName = PointCloudAnalysisColormapPreset.rainbow;
      if (isColormapPresetName(newColormap)) {
        colormapPoints.colormap = colormapPresets[newColormap];
        colormapName = PointCloudAnalysisColormapPreset[newColormap];
      }
      setColorBarColors(computeColorBarColors());
      dispatch(
        setAnalysisColormap({
          analysisId: analysis.id,
          colormap: colormapName,
        }),
      );
    },
    [analysis.id, colormapPoints, computeColorBarColors, dispatch],
  );

  const formatToleranceText = useCallback(
    () =>
      unitOfMeasure === "metric"
        ? convertUnit(
            analysis.tolerance,
            MeasurementUnits.meters,
            MeasurementUnits.millimeters,
          ).toFixed(0)
        : convertUnit(
            analysis.tolerance,
            MeasurementUnits.meters,
            MeasurementUnits.inches,
          ).toFixed(3),
    [analysis.tolerance, unitOfMeasure],
  );

  const [toleranceText, setToleranceText] = useState("");
  const [toleranceChanged, setToleranceChanged] = useState(false);

  useEffect(() => {
    // reset toleranceChanged flag when unit of measure is changed
    setToleranceChanged(false);
  }, [unitOfMeasure]);

  useEffect(() => {
    // avoid reset text when tolerance is changed from the textbox
    if (!toleranceChanged) {
      setToleranceText(formatToleranceText());
    }
  }, [formatToleranceText, toleranceChanged]);

  const [toleranceError, setToleranceError] = useState("");
  const changeTolerance = useCallback(
    (newText: string) => {
      setToleranceText(newText);
      const newTolerance = Number(newText);
      if (isNaN(newTolerance)) {
        setToleranceError("Invalid number");
      } else {
        const tolerance = Math.abs(
          unitOfMeasure === "metric"
            ? convertUnit(
                newTolerance,
                MeasurementUnits.millimeters,
                MeasurementUnits.meters,
              )
            : convertUnit(
                newTolerance,
                MeasurementUnits.inches,
                MeasurementUnits.meters,
              ),
        );
        colormapPoints.minColorDeviation = -tolerance;
        colormapPoints.maxColorDeviation = +tolerance;
        setToleranceError("");
        setToleranceChanged(true);
        dispatch(setAnalysisTolerance({ analysisId: analysis.id, tolerance }));
      }
    },
    [analysis.id, colormapPoints, dispatch, unitOfMeasure],
  );

  const { minColorLabel, maxColorLabel } = useMemo(() => {
    const toleranceText = formatToleranceText();
    const unitText = unitOfMeasure === "metric" ? "mm" : "in";
    const minColorLabel = `-${toleranceText}${unitText}`;
    const maxColorLabel = `${toleranceText}${unitText}`;
    return { minColorLabel, maxColorLabel };
  }, [formatToleranceText, unitOfMeasure]);

  return (
    <Grid container spacing={1} alignItems="center" sx={{ width: "400px" }}>
      <Grid item xs={5}>
        <Dropdown
          options={colormapOptions}
          value={colormapName}
          dark
          onChange={(e) => changeColormap(e.target.value)}
        />
      </Grid>
      <Grid item xs={7}>
        <ColorBar colors={colorBarColors} />
      </Grid>
      <Grid item xs={2}>
        <FaroText variant="labelL" sx={{ color: neutral[0] }}>
          Tolerance
        </FaroText>
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          dark
          text={toleranceText}
          error={toleranceError}
          onTextChanged={changeTolerance}
        />
      </Grid>
      <Grid item xs={1}>
        <FaroText variant="labelL" sx={{ color: neutral[0] }}>
          {unitOfMeasure === "metric" ? "mm" : "in"}
        </FaroText>
      </Grid>
      <Grid item xs={7}>
        <ColorScaleLineIndicator
          minColorLabel={minColorLabel}
          maxColorLabel={maxColorLabel}
        />
      </Grid>
    </Grid>
  );
}

type ColorScaleLineIndicatorProps = {
  /** Min color deviation label */
  minColorLabel: string;

  /** Max color deviation label */
  maxColorLabel: string;
};

/**
 * Color scale line indicator of the min and max color deviations.
 *      min           max
 *   ----|------+------|----
 * The positions of the vertical lines match the color bar ratios.
 *
 * @returns Color scale line indicator
 */
function ColorScaleLineIndicator({
  minColorLabel,
  maxColorLabel,
}: ColorScaleLineIndicatorProps): JSX.Element {
  return (
    <Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" width="30%" justifyContent="center">
          <FaroText variant="bodyS" color={neutral[0]}>
            {minColorLabel}
          </FaroText>
        </Stack>
        <Stack direction="row" width="30%" justifyContent="center">
          <FaroText variant="bodyS" color={neutral[0]}>
            {maxColorLabel}
          </FaroText>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Box component="div" width="15%" height="1px" bgcolor={neutral[0]} />
        <Box component="div" width="1px" height="10px" bgcolor={neutral[0]} />
        <Box component="div" width="35%" height="1px" bgcolor={neutral[0]} />
        <Box component="div" width="1px" height="8px" bgcolor={neutral[0]} />
        <Box component="div" width="35%" height="1px" bgcolor={neutral[0]} />
        <Box component="div" width="1px" height="10px" bgcolor={neutral[0]} />
        <Box component="div" width="15%" height="1px" bgcolor={neutral[0]} />
      </Stack>
    </Stack>
  );
}
