import { ElementIconType } from "@/components/ui/icons";
import { assert, generateGUID } from "@faro-lotv/foundation";
import { isIElementGenericPointCloud } from "@faro-lotv/ielement-types";
import {
  selectChildDepthFirst,
  selectIElement,
  selectRootId,
} from "@faro-lotv/project-source";
import {
  createMutationAddEmptyLayerSection,
  createMutationGenerateFloorplansForPointCloud,
  isElementAllowedForFloorplanGeneration,
} from "@faro-lotv/service-wires";
import { ContextMenuAction, ContextMenuActionType } from "../action-types";

/**
 * Trigger the generation of floorplan(s) for the selected model
 */
export const GENERATE_FLOORPLAN: ContextMenuAction = {
  type: ContextMenuActionType.generateFloorplan,
  label: "Generate floorplan",
  icon: ElementIconType.DownloadIcon,
  handler: async ({
    elementID,
    state,
    apiClients: { projectApiClient },
    openToast,
    errorHandlers: { handleErrorWithToast },
  }) => {
    const rootId = selectRootId(state);
    assert(rootId, "Can only create empty layer with root ID.");

    const element = selectIElement(elementID)(state);

    const datasetSection = selectChildDepthFirst(
      element,
      isElementAllowedForFloorplanGeneration,
    )(state);
    assert(datasetSection, "Can't find suitable dataset section.");

    const pcElement = selectChildDepthFirst(
      datasetSection,
      isIElementGenericPointCloud,
    )(state);
    assert(pcElement, "Unable to find suitable point cloud.");

    const layerId = generateGUID();

    const addEmptyLayerMutation = createMutationAddEmptyLayerSection({
      layerId,
      rootId,
      name: `Empty layer for section ${datasetSection.id}`,
      sectionId: datasetSection.id,
    });
    const floorplanGenerationMutation =
      createMutationGenerateFloorplansForPointCloud({
        rootId,
        name: `Floorplan generation element for layer ${layerId}`,
        uri: pcElement.uri,
        layerId,
        md5Hash: pcElement.md5Hash,
        fileName: pcElement.fileName,
        fileSize: pcElement.fileSize,
      });

    try {
      await projectApiClient.applyMutations([
        addEmptyLayerMutation,
        floorplanGenerationMutation,
      ]);

      openToast({
        title:
          "Generation of floorplan(s) have been started. You will receive the files via email once it's finished.",
        variant: "success",
      });
    } catch (error) {
      handleErrorWithToast({ title: "Unable to generate floorplan", error });
    }
  },
};
