import { createAnnotationFields } from "@/components/ui/annotations/annotation-fields";
import {
  AnnotationCreationData,
  isExternalAnnotationData,
} from "@/components/ui/annotations/annotation-props";
import { createAttachments } from "@/components/ui/annotations/attachment-mutations";
import { useCurrentArea } from "@/modes/mode-data-context";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import { assert, generateGUID, GUID } from "@faro-lotv/foundation";
import {
  fetchProjectIElements,
  selectAdvancedMarkupTemplateIds,
  selectProjectId,
} from "@faro-lotv/project-source";
import {
  createMutationAddLabel,
  createMutationAddMarkup,
  createMutationAddMarkupPolygon,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback } from "react";
import {
  PointAnnotationData,
  selectAddPolygonMutationData,
} from "../annotation-mutation-utils";

export type CreateSinglePointAnnotation = (
  annotation: PointAnnotationData,
  details: AnnotationCreationData,
) => Promise<GUID>;

/**
 * @returns a function to save a single point annotation
 */
export function useCreateSinglePointAnnotation(): CreateSinglePointAnnotation {
  const projectId = useAppSelector(selectProjectId);
  const currentUser = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const { projectApiClient } = useApiClientContext();
  const appStore = useAppStore();
  const { area } = useCurrentArea();

  return useCallback<CreateSinglePointAnnotation>(
    (annotation, details) => {
      // The creation of external annotations is not supported yet. This will be introduced by the following issue:
      // https://faro01.atlassian.net/browse/CADBIM-895
      assert(
        !isExternalAnnotationData(details),
        "data is not a Sphere XG annotation",
      );
      const {
        title,
        assignee,
        description,
        dueDate,
        status,
        newAttachments,
        tags,
      } = details;
      async function createAnnotation(): Promise<GUID> {
        assert(currentUser, "Expected a logged in user");
        assert(projectId, "Expected a project ID");

        const appState = appStore.getState();

        const markupPolygonId = generateGUID();

        const mutationData = selectAddPolygonMutationData(
          annotation,
          area,
        )(appState);
        assert(
          mutationData,
          "Unable to compute the data to store for the new annotation",
        );

        const addMarkupPolygonMutation = createMutationAddMarkupPolygon({
          ...mutationData,
          newElementId: markupPolygonId,
          name: title,
          typeHint: annotation.type,
        });

        const markupId = generateGUID();
        const templateIds = selectAdvancedMarkupTemplateIds(appState);
        assert(
          templateIds,
          "Expected project to have an advanced markup template",
        );

        const markupFields = createAnnotationFields({
          assignee,
          status,
          dueDate,
          ...templateIds,
          currentUserId: currentUser.id,
          markupId,
          rootId: mutationData.rootId,
        });

        const addMarkupMutation = createMutationAddMarkup({
          id: markupId,
          templateId: templateIds.templateId,
          rootId: mutationData.rootId,
          name: title,
          description: description ?? "",
          annotationId: markupPolygonId,
          markupFields,
        });

        const attachmentsMutations = createAttachments(
          mutationData.rootId,
          markupId,
          newAttachments,
        );

        const tagsMutations =
          tags?.map((tag) => createMutationAddLabel(markupId, tag.id)) ?? [];

        await projectApiClient.applyMutations([
          addMarkupPolygonMutation,
          addMarkupMutation,
          ...attachmentsMutations,
          ...tagsMutations,
        ]);

        await dispatch(
          fetchProjectIElements({
            fetcher: () =>
              projectApiClient.getAllIElements({
                ancestorIds: [mutationData.sectionId],
              }),
          }),
        );

        return markupId;
      }

      return createAnnotation();
    },
    [currentUser, projectId, appStore, area, projectApiClient, dispatch],
  );
}
