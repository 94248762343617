import {
  EventType,
  ToggleUnitOfMeasureEventProperties,
} from "@/analytics/analytics-events";
import { useAppSelector } from "@/store/store-hooks";
import {
  selectRootIElement,
  useLocalStorage,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { useCallback } from "react";

export type UseUnitOfMeasureReturn = {
  /** Current selected unit of measure to use */
  unitOfMeasure: SupportedUnitsOfMeasure;

  /** Function to change the unit of measure to use */
  setUnitOfMeasure(unit: SupportedUnitsOfMeasure): void;
};

/**
 * Access the session configured unit of measure to use for labels
 *
 * @param defaultUnit the unit to use if nothing is in the session store
 * @returns The unit of measure to use an a support function to update the session store
 */
export function useUnitOfMeasure(
  defaultUnit: SupportedUnitsOfMeasure,
): UseUnitOfMeasureReturn {
  const [unitOfMeasure, setUnitOfMeasure] = useLocalStorage(
    "unitOfMeasure",
    defaultUnit,
  );

  return {
    unitOfMeasure,
    setUnitOfMeasure,
  };
}

/**
 * @returns The current unit of measure of the project, if defined in the project root
 */
export function useProjectUnitOfMeasure(): UseUnitOfMeasureReturn {
  const root = useAppSelector(selectRootIElement);
  return useUnitOfMeasure(
    root?.metaDataMap?.projectSettings?.unitSystem ?? "metric",
  );
}

/**
 * @param trackAnalyticsEvent Whether to track the event when the unit of measure is toggled
 * @returns The current unit of measure of the project and a toggle function to switch between metric and imperial
 */
export function useToggleUnitOfMeasure(trackAnalyticsEvent: boolean): {
  unitOfMeasure: SupportedUnitsOfMeasure;
  toggleUnitOfMeasure(): void;
} {
  const { unitOfMeasure, setUnitOfMeasure } = useProjectUnitOfMeasure();

  const toggleUnitOfMeasure = useCallback(() => {
    const newUnitOfMeasure = unitOfMeasure === "metric" ? "us" : "metric";

    if (trackAnalyticsEvent) {
      Analytics.track<ToggleUnitOfMeasureEventProperties>(
        EventType.toggleUnitOfMeasure,
        {
          newValue:
            newUnitOfMeasure === "metric" ? newUnitOfMeasure : "imperial",
        },
      );
    }

    setUnitOfMeasure(newUnitOfMeasure);
  }, [setUnitOfMeasure, trackAnalyticsEvent, unitOfMeasure]);

  return { unitOfMeasure, toggleUnitOfMeasure };
}
