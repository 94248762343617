import { ElementIconType } from "@/components/ui/icons";
import { ErrorHandlers } from "@/errors/components/error-handling-context";
import { AppDispatch, RootState } from "@/store/store";
import { CreateDialogProps, ToastReturn } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { IElement } from "@faro-lotv/ielement-types";
import { ApiClients, ProjectApi } from "@faro-lotv/service-wires";

/** An async function to create a dialog */
export type CreateDialogFn = (options: CreateDialogProps) => Promise<boolean>;

type ContextMenuActionHandler = (
  args: ContextMenuActionHandlerArgs,
) => Promise<void> | void;

/** Different types a context menu's action can have. */
export enum ContextMenuActionType {
  delete = "delete",
  download = "download",
  adjustTrajectory = "adjustTrajectory",
  deleteVideoRecording = "deleteVideoRecording",
  registerAll = "registerAll",
  inspectAndPublish = "inspectAndPublish",
  scaleFloor = "scaleFloor",
  openAlignmentWizard = "openAlignmentWizard",
  editElement = "editElement",
  moveDataSession = "moveDataSession",
  setCloudAsGeoReferenced = "setCloudAsGeoReferenced",
  generateFloorplan = "generateFloorplan",
}

export type NestedMenuHandlerFnArgs = {
  /** Id of the nested menu element */
  nestedMenuItemId?: GUID;

  /** Id of the menu item project tree. */
  menuItemId: GUID;

  /** Current state of the application. */
  state: RootState;

  /** Project API client */
  projectApi: ProjectApi;
};

export type NestedMenuObject = {
  /** Title of the nested menu */
  title?: string;

  /** Options to be rendered in the nested menu */
  options: Array<{
    /** Id of the nested menu item */
    id?: GUID;

    /** Label of the nested menu item */
    label: string;

    /** Icon to be rendered in the nested menu item */
    icon?: ElementIconType;

    /** Tagline of the nested menu item */
    tagline?: string;

    /** Icon to be rendered in the nested menu item */
    onClick(props: NestedMenuHandlerFnArgs): void;
  }>;

  /** Function to decide if the nested menu should be rendered or not */
  shouldShowNestedMenu?(elementId: GUID, state: RootState): boolean;
};

type NestedMenuFn = (elementId: GUID, state: RootState) => NestedMenuObject;

export type ContextMenuAction = {
  /** Icon to use for the action */
  icon: ElementIconType;

  /** Text to display */
  label: string;

  /** Type of the action */
  type: ContextMenuActionType;

  /**
   * Should the action be disabled with the provided message?
   *
   * If not defined, the action is always enabled when visible.
   *
   * @param iElement The element to decide for if its node should be disabled.
   * @param state The current application state.
   * @returns A message to display as a tooltip if the action is disabled or `undefined` if it's enabled.
   */
  disabledMessageForNode?(
    iElement: IElement,
    state: RootState,
  ): string | undefined;

  /** optional tooltip message when menu item is enabled*/
  tooltipMessage?: string;

  /** A nested menu which needs to be rendered on click of a menu item */
  nestedMenuProps?: NestedMenuObject | NestedMenuFn;

  /** Callback to handle click events on the menu item. */
  handler?: ContextMenuActionHandler;
};

export type ContextMenuActionHandlerArgs = {
  /** Id of the element in the project tree. */
  elementID: GUID;

  /** Current state of the application. */
  state: RootState;

  /** Function to update the state. */
  dispatch: AppDispatch;

  /** API clients to talk to the Sphere XG backends. */
  apiClients: ApiClients;

  /** An async function to create a dialog */
  createDialog: CreateDialogFn;

  /** Display a toast to the user. */
  openToast: ToastReturn["openToast"];

  /** Error handlers to display errors to the user */
  errorHandlers: ErrorHandlers;

  /** function to Disable/Enable the confirmation button (the button is enabled by default) */
  setConfirmButtonDisabled?(isDisabled: boolean): void;
};
