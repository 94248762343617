import { Theme } from "@emotion/react";
import { FaroText, FontWeights } from "@faro-lotv/flat-ui";
import { Stack, SxProps } from "@mui/material";

type CrateAreaTitleProps = {
  /** Optional styling applied to the component */
  sx?: SxProps<Theme>;
};

/**
 * @returns a component with a title and a learn more link
 */
export function CrateAreaTitle({ sx }: CrateAreaTitleProps): JSX.Element {
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      sx={{
        justifyContent: {
          xs: "center",
          sm: "flex-start",
        },
        borderBottom: ({ palette }) => ({
          sm: `2px solid ${palette.gray100}80`,
        }),
        ...sx,
      }}
    >
      <FaroText variant="bodyM" sx={{ fontWeight: FontWeights.SemiBold }}>
        Project Set Up
      </FaroText>
    </Stack>
  );
}
