import { Mode } from "@/modes/mode";
import { CreateAreaModeOverlay } from "./create-area-mode-overlay";

export const createAreaMode: Mode = {
  name: "createArea",
  ModeScene: () => null,
  ModeOverlay: CreateAreaModeOverlay,
  exclusive: {
    title: "Create Area",
    useDefaultHeader: true,
  },
};
