import { EventType } from "@/analytics/analytics-events";
import { selectClippingBox } from "@/store/clipping-box-selectors";
import { setClippingBox } from "@/store/clipping-box-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  BoxControls,
  BoxControlsRef,
  useControlsLock,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { useCallback, useMemo, useRef, useState } from "react";
import { Box3, Plane, Quaternion, Vector3, Vector4Tuple } from "three";

function toVec4Tuple(q: Quaternion): Vector4Tuple {
  return [q.x, q.y, q.z, q.w];
}

export type ClipSceneToolProps = {
  /** Whether the tool should be active @default true */
  active?: boolean;

  /** The current model's bounding box, to initialize the clipbox with. */
  modelBox?: Box3;

  /** Whether the user is editing the clipping box via the controls */
  clippingBoxChanging?(changing: boolean): void;

  /** Optional callback used when the clipping planes change */
  clippingPlanesChanged?(planes: Plane[]): void;
};

/**
 * @returns An interactable gizmo that allows the user to define a clipping box
 * in the 3D scene. The clipping box is saved to store when the clipscene tool
 * is deactivated, and loaded from store when the tool is activated.
 */
export function ClipSceneTool({
  active = true,
  modelBox,
  clippingBoxChanging,
  clippingPlanesChanged,
}: ClipSceneToolProps): JSX.Element | null {
  const dispatch = useAppDispatch();

  const boxControlsRef = useRef<BoxControlsRef>(null);

  const clippingBox = useAppSelector(selectClippingBox);

  // The initial clipping box is either the one saved in the store, or
  // the one provided in the initialization prop
  const initialClippingBox = useMemo<
    { size: Vector3; rotation: Quaternion; position: Vector3 } | undefined
  >(() => {
    if (clippingBox) {
      return {
        size: new Vector3().fromArray(clippingBox.size),
        rotation: new Quaternion().fromArray(clippingBox.rotation),
        position: new Vector3().fromArray(clippingBox.position),
      };
    }
    if (modelBox) {
      return {
        size: modelBox.getSize(new Vector3()),
        rotation: new Quaternion(),
        position: modelBox.getCenter(new Vector3()),
      };
    }
  }, [clippingBox, modelBox]);

  // When the user is editing the clipping box, the default controls should be disabled
  const [isEditingClippingBox, setIsEditingClippingBox] = useState(false);
  useControlsLock(isEditingClippingBox);

  // everytime the user finishes an edit to the clipbox, the clipbox is written to the store.
  const clipboxEditFinished = useCallback(() => {
    Analytics.track(EventType.editClippingBox);

    setIsEditingClippingBox(false);
    clippingBoxChanging?.(false);
    const box = boxControlsRef.current;
    if (!box) return;
    dispatch(
      setClippingBox({
        position: box.pos.toArray(),
        size: box.size.toArray(),
        rotation: toVec4Tuple(box.rot),
      }),
    );
  }, [dispatch, clippingBoxChanging]);

  const clipboxEditStarted = useCallback(() => {
    setIsEditingClippingBox(true);
    clippingBoxChanging?.(true);
  }, [setIsEditingClippingBox, clippingBoxChanging]);

  if (!active || !initialClippingBox) return null;

  return (
    <BoxControls
      ref={boxControlsRef}
      onInteractionStarted={clipboxEditStarted}
      onInteractionStopped={clipboxEditFinished}
      size={initialClippingBox.size}
      position={initialClippingBox.position}
      rotation={initialClippingBox.rotation}
      enableRotateX={false}
      enableRotateY={false}
      clipInside={false}
      clippingPlanesChanged={clippingPlanesChanged}
    />
  );
}
