import {
  CopyMeasurementToClipboardEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import { useProjectUnitOfMeasure } from "@/hooks/use-unit-of-measure";
import { useAppSelector } from "@/store/store-hooks";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { useToast } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { IElement, isIElementMeasurePolygon } from "@faro-lotv/ielement-types";
import { useMemo } from "react";
import { Vector3 } from "three";
import { computeMultiPointMeasurementDescription } from "../measurements/measure-utils";

type CopyToClipboardCallback = () => void;

/**
 *  @returns A callback to copy the content of a measurement to clipboard, or undefined if the iElement is not a MeasurePolygon
 * @param annotation The annotation whose info we want to copy to clipboard
 */
export function useCopyToClipboard(
  annotation: IElement,
): CopyToClipboardCallback | undefined {
  const { unitOfMeasure } = useProjectUnitOfMeasure();

  const { openToast } = useToast();

  const worldMatrix = useAppSelector(selectIElementWorldMatrix4(annotation.id));

  return useMemo(() => {
    if (!isIElementMeasurePolygon(annotation)) return;

    const points = annotation.points.map((p) =>
      new Vector3(p.x, p.y, p.z).applyMatrix4(worldMatrix),
    );

    return () => {
      // Copy the text to the clipboard.
      Analytics.track<CopyMeasurementToClipboardEventProperties>(
        EventType.copyMeasurementToClipboard,
        {
          via: "annotation dialog",
        },
      );
      navigator.clipboard.writeText(
        computeMultiPointMeasurementDescription(
          points,
          annotation.isClosed,
          unitOfMeasure,
        ),
      );
      openToast({
        title: "Copied to clipboard",
      });
    };
  }, [annotation, openToast, unitOfMeasure, worldMatrix]);
}
