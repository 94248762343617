import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import { PointCloudObject } from "@/object-cache";
import { selectActiveAnalysis } from "@/store/point-cloud-analysis-tool-selector";
import {
  PointCloudAnalysis,
  setActiveAnalysis,
  setAnalysisVisibility,
} from "@/store/point-cloud-analysis-tool-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectVisibilityDistance } from "@/store/view-options/view-options-selectors";
import { MeasuresSorter } from "@/tools/multi-point-measures/measures-sorter";
import { UPDATE_CAMERA_MONITOR_PRIORITY } from "@faro-lotv/spatial-ui";
import { useFrame, useThree } from "@react-three/fiber";
import { useMemo, useState } from "react";
import { AnnotationVisibility } from "../annotations/annotation-utils";
import { CollapsedAnalysisRenderer } from "./collapsed-analysis-renderer";
import { ColormapAnalysisRenderer } from "./colormap-analysis-renderer";

type AnalysesRendererProps = {
  /** The current active point cloud */
  pointCloud: PointCloudObject;

  /** The list of analyses to render */
  analyses: PointCloudAnalysis[];
};

/** @returns The renderer of the analyses stored in the app store */
export function AnalysesRenderer({
  analyses,
  pointCloud,
}: AnalysesRendererProps): JSX.Element {
  const labelContainer = useViewOverlayRef();
  const dispatch = useAppDispatch();

  const camera = useThree((s) => s.camera);

  const sorter = useMemo(() => new MeasuresSorter(camera), [camera]);

  const [analysisMarkerVisibility, setAnalysisMarkerVisibility] = useState(
    new Array<AnnotationVisibility>(),
  );

  const visibilityDistance = useAppSelector(selectVisibilityDistance);
  const activeAnalysis = useAppSelector(selectActiveAnalysis);

  useFrame(({ camera }, delta) => {
    sorter.update(
      camera,
      analyses,
      undefined,
      delta,
      visibilityDistance,
      visibilityDistance,
    );
    if (sorter.dirty()) {
      // Updating React states inside useFrame leads to downgraded performances.
      // Therefore, the 'analysisMarkerVisibility' state is updated only once every
      // 'sorter.secsBeforeUpdate' seconds.
      setAnalysisMarkerVisibility(sorter.measuresVisibility.slice());
      sorter.resetDirty();
    }
  }, UPDATE_CAMERA_MONITOR_PRIORITY);

  return (
    <>
      {analyses.map((analysis, index) => {
        if (analysis.parentId !== pointCloud.iElement.id) {
          return null;
        }

        const visible =
          analysis.id === activeAnalysis?.id && analysis.isVisible;
        return (
          <>
            {visible && (
              <ColormapAnalysisRenderer
                key={analysis.id}
                pointCloud={pointCloud}
                analysis={analysis}
              />
            )}

            <CollapsedAnalysisRenderer
              visible={
                !visible &&
                analysisMarkerVisibility[index] !==
                  AnnotationVisibility.NotVisible
              }
              polygonSelection={analysis.polygonSelection}
              parentRef={labelContainer}
              onClick={() => {
                dispatch(
                  setAnalysisVisibility({
                    analysisId: analysis.id,
                    visible: true,
                  }),
                );
                dispatch(setActiveAnalysis(analysis));
              }}
            />
          </>
        );
      })}
    </>
  );
}
