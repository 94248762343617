import { blue, neutral } from "@faro-lotv/flat-ui";
import ChevronRightSharpIcon from "@mui/icons-material/ChevronRightSharp";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import { Stack, SvgIconProps, SxProps } from "@mui/material";
import { StackProps } from "@mui/system";
import { CSSProperties, PropsWithChildren } from "react";
import { NodeApi } from "react-arborist";

/** Fixed node height in pixels. React arborist requires each node to have a fixed height to compute which nodes are visible */
export const TREE_NODE_HEIGHT = 33;

export type TreeNodeProps<T> = {
  /** Data for this node */
  node: NodeApi<T>;

  /** True if the node is disabled */
  isDisabled?: boolean;

  /** True if the node can be selected @default true */
  isSelectable?: boolean;

  /**
   * True if the node should expand when it is clicked
   *
   * @default true
   */
  shouldExpandNodeOnClick?: boolean;

  /**
   * Whether the node should be deselected when clicked in its selected state
   */
  shouldDeselectOnClick?: boolean;

  /**
   * Styles to apply to this node
   *
   * Note: Those are passed directly by the arborist's Tree component
   */
  style?: CSSProperties;

  /** Pointer enter event on the tree node */
  onPointerEnter?: StackProps["onPointerEnter"];

  /** Pointer leave event on the tree node */
  onPointerLeave?: StackProps["onPointerLeave"];
};

/**
 * @returns a styled tree node which can be used as a base for creating a custom tree node
 */
export function TreeNode<T>({
  node,
  isDisabled,
  isSelectable = true,
  shouldExpandNodeOnClick = true,
  shouldDeselectOnClick,
  onPointerEnter,
  onPointerLeave,
  children,
}: PropsWithChildren<TreeNodeProps<T>>): JSX.Element {
  return (
    <Stack
      flexDirection="row"
      pl={1}
      alignItems="center"
      onClick={(ev) => {
        if (shouldExpandNodeOnClick) {
          node.open();
        }

        if (shouldDeselectOnClick && node.isSelected) {
          node.deselect();

          // Workaround for onSelect callback not being fired after deselecting nodes through code
          // see https://github.com/brimdata/react-arborist/issues/224
          node.tree.props.onSelect?.(node.tree.selectedNodes);

          // prevent node from being selected again
          ev.stopPropagation();
        }

        if (!isSelectable) {
          ev.stopPropagation();
        }
      }}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      overflow="hidden"
      flexGrow={1}
      sx={{
        // Need to remain aligned to the rowHeight parameter
        height: `${TREE_NODE_HEIGHT}px`,
        "&:hover": isSelectable
          ? {
              backgroundColor: node.isSelected
                ? blue[500]
                : `${neutral[500]}19`,
              cursor: "pointer",
            }
          : undefined,
        "&:hover .MuiBadge-badge": isSelectable
          ? {
              // The color rgb(229, 229, 229)  matches the backgroundColor rgba(0, 0, 0, 0.1) for the hover selector above.
              borderColor: node.isSelected ? blue[500] : "rgb(229, 229, 229)",
            }
          : undefined,
        color: node.isSelected ? "white" : neutral[800],
        backgroundColor: node.isSelected ? blue[500] : "white",
        ".MuiBadge-badge": {
          borderColor: node.isSelected ? blue[500] : "white",
        },
      }}
    >
      <Stack
        flexDirection="row"
        height="100%"
        alignItems="center"
        pl="0.1875em"
        overflow="hidden"
        flexGrow={1}
        sx={{ userSelect: "none", opacity: isDisabled ? 0.5 : 1 }}
      >
        <ExpandNodeIcon
          isExpanded={!node.isClosed}
          onClick={(ev) => {
            ev.stopPropagation();
            node.toggle();
          }}
          sx={{
            display: "flex",
            mr: 0.5,
            visibility: node.isLeaf ? "hidden" : "unset",
            fontSize: "1.125em",
            cursor: node.isLeaf ? undefined : "pointer",
          }}
        />
        {children}
      </Stack>
    </Stack>
  );
}

type ExpandNodeIconProps = {
  /** Boolean to know if the node is expanded or closed */
  isExpanded: boolean;

  /** Optional style to apply to the image */
  sx?: SxProps;

  /** Callback called when the icon is clicked */
  onClick?: SvgIconProps["onClick"];
};

/**
 * This component is required, because by simply rotating, with css, the image will become blurry.
 *
 * @returns an arrow icon. The arrow points to below if the node is expanded, otherwise it points to the right.
 */
function ExpandNodeIcon({
  isExpanded,
  sx,
  onClick,
}: ExpandNodeIconProps): JSX.Element {
  return isExpanded ? (
    <ExpandMoreSharpIcon sx={sx} onClick={onClick} />
  ) : (
    <ChevronRightSharpIcon sx={sx} onClick={onClick} />
  );
}
