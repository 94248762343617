import { CadModelObject, PointCloudObject } from "@/object-cache";
import { useAppSelector } from "@/store/store-hooks";
import { selectIElementWorldTransform } from "@faro-lotv/app-component-toolbox";
import { GUID } from "@faro-lotv/ielement-types";
import { CadModel, LodPointCloud, StreamCadModel } from "@faro-lotv/lotv";
import { useMemo } from "react";
import { Box3, Matrix4, Object3D } from "three";

/**
 * Compute a 3d object bounding box
 *
 * @param object3d The 3d object
 * @param id The id of this object in the ielement project
 * @returns The object bounding box in world space
 */
export function useObjectBoundingBox(
  object3d: Object3D | null,
  id?: GUID,
): Box3 | undefined {
  const { worldMatrix } = useAppSelector(selectIElementWorldTransform(id));

  const box = useMemo(() => {
    // 3D objects world pos may be invalid if they've never been rendered
    // So we can't expect it's worldMatrix to be correct
    // So we compute the box, then adjust using the world matrix from the ielement project
    const box = new Box3();
    if (object3d === null) return;

    // The LOD PointCloud has no geometry itself, so we cannot
    // expand the bounding box using the 3D object.
    if (object3d instanceof LodPointCloud) {
      box.expandByPoint(object3d.tree.boundingBox.min);
      box.expandByPoint(object3d.tree.boundingBox.max);
    } else if (
      object3d instanceof StreamCadModel ||
      object3d instanceof CadModel
    ) {
      box.union(object3d.boundingBox());
    } else {
      box.expandByObject(object3d);
      if (box.isEmpty()) {
        // cad might not be loaded yet; we should not return empty box in this case
        return;
      }
    }

    box.applyMatrix4(new Matrix4().fromArray(worldMatrix));
    return box;
  }, [object3d, worldMatrix]);

  return box;
}

/**
 *
 * @param pointCloud The current point cloud in the scene
 * @param cad The current CAD in the scene
 * @returns The cumulative bounding box of the given 3D models
 */
export function use3DmodelsBoundingBox(
  pointCloud: PointCloudObject | null,
  cad: CadModelObject | null | Error,
): Box3 {
  const cloudBox = useObjectBoundingBox(pointCloud, pointCloud?.iElement.id);

  const validCad = cad instanceof Error ? null : cad;
  const cadBox = useObjectBoundingBox(validCad, validCad?.iElement.id);

  return useMemo(() => {
    const box = new Box3();
    if (cloudBox) {
      box.union(cloudBox);
    }
    if (cadBox) {
      box.union(cadBox);
    }

    return box;
  }, [cadBox, cloudBox]);
}
