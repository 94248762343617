import { ElementIconType } from "@/components/ui/icons";
import { assert } from "@faro-lotv/foundation";
import { ContextMenuAction, ContextMenuActionType } from "../action-types";

export const REGISTER_ALL_ACTION: ContextMenuAction = {
  type: ContextMenuActionType.registerAll,
  label: "Register Point Clouds",
  icon: ElementIconType.RegistrationInteractiveIcon,
  handler: async ({ openToast, apiClients, elementID }) => {
    const registrationApi = apiClients.registrationApiClient;
    assert(registrationApi, "Registration API must be available");

    openToast({
      title: "The data set is being registered",
      message: "You will be notified when the registration is complete.",
      variant: "info",
    });

    await registrationApi.startMultiCloudRegistration({
      dataSetId: elementID,
      applyRegistrationResults: false,
    });
  },
};
