import {
  CreateAnnotationEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import {
  EmbeddedToolbar,
  EmbeddedToolbarButton,
} from "@/components/ui/embedded-toolbar";
import { useAnnotationPermissions } from "@/hooks/use-annotation-permissions";
import { ComponentsToDisplay } from "@/store/measurement-tool-slice";
import {
  ANNOTATION_ZINDEX_RANGE_MAP,
  AnnotationIcon,
  Checkmark2Icon,
  CoordinatesIcon,
  CopyFileIcon,
  Data3dIcon,
  DeleteIcon,
  MeasureShowAxesIcon,
  neutral,
} from "@faro-lotv/app-component-toolbox";
import { Analytics } from "@faro-lotv/foreign-observers";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { Stack, Tooltip, Typography } from "@mui/material";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Vector3 } from "three";
import { AppAwareHtml } from "../app-aware-html";
import { MEASURE_ANIMATION_LENGTH } from "./measure-constants";

/** Time (ms) that the copied message box will be shown for, after which it will stop rendering */
const COPIED_POPUP_LIFE_TIME = 2000;

// Max value for z-index, since the measurements' labels created with Html from drei use a computed z-index
// that could be very big and would occlude the message.
const MAX_ZINDEX_VALUE = 2147483647;

declare type MeasureActionBarProp = {
  /** 3D point to anchor the html component to. */
  anchorPoint: Vector3;

  /** Is this measurement active. */
  isActive: boolean;

  /** The parent that the label should have in the html DOM */
  parentRef: MutableRefObject<HTMLElement>;

  /** Current selected unit of measure used to display the distances */
  unitOfMeasure: SupportedUnitsOfMeasure;

  /** Which components are displayed in a measurement. Undefined is not a measurement. */
  componentsToDisplay?: ComponentsToDisplay;

  /** Function to call when the create annotation button is clicked  */
  onCreateAnnotation?(): void;

  /** Function to call when the copy to clipboard button is clicked. */
  onCopyToClipboard(): void;

  /** Function to call when the delete measurement button is clicked. */
  onDeleteMeasurement?(): void;

  /** Function to toggle between the available unit of measures */
  onToggleUnitOfMeasure(): void;

  /** Function to change the type of components to display */
  onChangeMeasurementComponentsToDisplay?(
    componentsToDisplay: ComponentsToDisplay,
  ): void;
};

/**
 * @returns an action bar with an option to copy the measurements and an option to delete the measurement.
 */
export function MeasureActionBar({
  anchorPoint,
  isActive,
  parentRef,
  unitOfMeasure,
  componentsToDisplay,
  onCreateAnnotation,
  onCopyToClipboard,
  onDeleteMeasurement,
  onToggleUnitOfMeasure,
  onChangeMeasurementComponentsToDisplay,
}: MeasureActionBarProp): JSX.Element | null {
  const buttonSize = "36px";

  const { canWriteAnnotations } = useAnnotationPermissions();
  const toolTipForCreateAnnotation = useMemo(() => {
    if (!canWriteAnnotations) {
      return "Annotation creation is only available for Project Editors";
    }
    return "Create annotation";
  }, [canWriteAnnotations]);

  // Check to know if the copied to clipboard message is rendered or not
  const [isCopiedPopupShown, setIsCopiedPopupShown] = useState(false);

  const clipboardBtnRef = useRef<HTMLButtonElement>(null);

  const [isSubMenuActive, setIsSubMenuActive] = useState(false);

  const pointerEvents = isActive ? "auto" : "none";

  // reset isSubMenuActive to false whenever isActive is false
  useEffect(() => {
    if (!isActive && isSubMenuActive) {
      setIsSubMenuActive(false);
    }
  }, [isActive, isSubMenuActive]);

  return (
    <>
      <AppAwareHtml
        portal={parentRef}
        position={anchorPoint}
        zIndexRange={ANNOTATION_ZINDEX_RANGE_MAP.toolbar}
        eps={-1}
        style={{
          pointerEvents,
          // Add offset to put the container on top of measurement label
          transform: "translate(-50% , -150% )",
        }}
      >
        <EmbeddedToolbar
          isActive={isActive}
          sx={{
            transition: `opacity ${MEASURE_ANIMATION_LENGTH}s linear`,
            backgroundColor: neutral[999],
          }}
        >
          <Tooltip title={toolTipForCreateAnnotation} placement="bottom">
            <EmbeddedToolbarButton
              aria-label="create annotation"
              buttonSize={buttonSize}
              value="Create annotation"
              disabled={!canWriteAnnotations}
              onClick={() => {
                setIsSubMenuActive(false);
                if (onCreateAnnotation) {
                  Analytics.track<CreateAnnotationEventProperties>(
                    EventType.createAnnotation,
                    { shape: "measurement" },
                  );

                  onCreateAnnotation();
                }
              }}
            >
              <AnnotationIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
          <Tooltip title="Change unit" placement="bottom">
            <EmbeddedToolbarButton
              aria-label="change unit"
              buttonSize={buttonSize}
              value="Change Unit"
              onClick={() => {
                setIsSubMenuActive(false);
                onToggleUnitOfMeasure();
              }}
            >
              <Typography>{unitOfMeasure === "metric" ? "m" : "ft"}</Typography>
            </EmbeddedToolbarButton>
          </Tooltip>
          <Tooltip
            title={isCopiedPopupShown ? <CopiedTooltip /> : "Copy to clipboard"}
            PopperProps={{
              sx: {
                zIndex: MAX_ZINDEX_VALUE,
              },
            }}
            placement="bottom"
            open={isCopiedPopupShown ? true : undefined}
            leaveDelay={isCopiedPopupShown ? COPIED_POPUP_LIFE_TIME / 2 : 0}
          >
            <EmbeddedToolbarButton
              aria-label="copy to clipboard"
              buttonSize={buttonSize}
              value="Copy to clipboard"
              ref={clipboardBtnRef}
              onClick={() => {
                setIsSubMenuActive(false);
                setIsCopiedPopupShown(true);

                // Set timeout to close the copied to clipboard message
                setTimeout(
                  () => setIsCopiedPopupShown(false),
                  COPIED_POPUP_LIFE_TIME,
                );

                onCopyToClipboard();
              }}
            >
              <CopyFileIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
          <ToggleComponentsButton
            componentsToDisplay={componentsToDisplay}
            onChangeMeasurementComponentsToDisplay={
              onChangeMeasurementComponentsToDisplay
            }
            onClick={() => setIsSubMenuActive(!isSubMenuActive)}
            buttonSize={buttonSize}
          />
          <Tooltip
            title="Delete"
            placement="bottom"
            PopperProps={{
              sx: {
                zIndex: MAX_ZINDEX_VALUE,
              },
            }}
          >
            <EmbeddedToolbarButton
              aria-label="delete measurement"
              buttonSize={buttonSize}
              value="Delete"
              onClick={() => {
                setIsSubMenuActive(false);
                onDeleteMeasurement?.();
              }}
            >
              <DeleteIcon sx={{ color: neutral[0] }} />
            </EmbeddedToolbarButton>
          </Tooltip>
        </EmbeddedToolbar>
      </AppAwareHtml>
      {isActive && isSubMenuActive && (
        <AppAwareHtml
          portal={parentRef}
          position={anchorPoint}
          zIndexRange={ANNOTATION_ZINDEX_RANGE_MAP.toolbar}
          eps={-1}
          style={{
            pointerEvents,
            // Add offset to put the container on top of measurement label
            transform: "translate(+50% , -16% )",
          }}
        >
          <EmbeddedToolbar
            isActive={isSubMenuActive}
            vertical
            sx={{
              transition: `opacity ${MEASURE_ANIMATION_LENGTH}s linear`,
              backgroundColor: neutral[999],
            }}
          >
            <Tooltip
              title="Display 3D distance"
              PopperProps={{
                sx: {
                  zIndex: MAX_ZINDEX_VALUE,
                },
              }}
              placement="right"
            >
              <EmbeddedToolbarButton
                aria-label="3d-components"
                buttonSize={buttonSize}
                value="3D"
                onClick={() => {
                  if (onChangeMeasurementComponentsToDisplay) {
                    onChangeMeasurementComponentsToDisplay(
                      ComponentsToDisplay.single3d,
                    );
                  }
                  setIsSubMenuActive(false);
                }}
              >
                <Data3dIcon sx={{ color: neutral[0] }} />
              </EmbeddedToolbarButton>
            </Tooltip>
            <Tooltip
              title="Display vertical and horizontal distances"
              PopperProps={{
                sx: {
                  zIndex: MAX_ZINDEX_VALUE,
                },
              }}
              placement="right"
            >
              <EmbeddedToolbarButton
                aria-label="horizontal-vertical-components"
                buttonSize={buttonSize}
                value="HorizontalVertical"
                onClick={() => {
                  if (onChangeMeasurementComponentsToDisplay) {
                    onChangeMeasurementComponentsToDisplay(
                      ComponentsToDisplay.heightAndHorizontal,
                    );
                  }
                  setIsSubMenuActive(false);
                }}
              >
                <MeasureShowAxesIcon sx={{ color: neutral[0] }} />
              </EmbeddedToolbarButton>
            </Tooltip>
            <Tooltip
              title="Display distances along x, y, and z axes"
              PopperProps={{
                sx: {
                  zIndex: MAX_ZINDEX_VALUE,
                },
              }}
              placement="left"
            >
              <EmbeddedToolbarButton
                aria-label="xyz-components"
                buttonSize={buttonSize}
                value="XYZ"
                onClick={() => {
                  if (onChangeMeasurementComponentsToDisplay) {
                    onChangeMeasurementComponentsToDisplay(
                      ComponentsToDisplay.xyz,
                    );
                    setIsSubMenuActive(false);
                  }
                }}
              >
                <CoordinatesIcon sx={{ color: neutral[0] }} />
              </EmbeddedToolbarButton>
            </Tooltip>
          </EmbeddedToolbar>
        </AppAwareHtml>
      )}
    </>
  );
}

/**
 * @returns the tooltip message after the user copies the measurement to the clipboard.
 */
function CopiedTooltip(): JSX.Element {
  return (
    <Stack direction="row" gap={1}>
      <Checkmark2Icon sx={{ width: "20px", height: "20px" }} />

      <Typography color="gray100" fontSize="14px">
        Copied to clipboard
      </Typography>
    </Stack>
  );
}

/** Parameters for ToggleComponentsButton component. */
declare type ToggleComponentsButtonProps = Pick<
  MeasureActionBarProp,
  "componentsToDisplay" | "onChangeMeasurementComponentsToDisplay"
> & {
  /** Size of the button */
  buttonSize: string;
  /** Called whenever the user click on the button */
  onClick(): void;
};

/**
 * @returns the button for toggling between displaying 3D and components distances.
 */
function ToggleComponentsButton({
  componentsToDisplay,
  onChangeMeasurementComponentsToDisplay,
  onClick,
  buttonSize,
}: ToggleComponentsButtonProps): JSX.Element {
  const tooltipForToggleMeasurementComponents = useMemo(() => {
    if (onChangeMeasurementComponentsToDisplay) {
      return "Change the components displayed for this measurement";
    }

    return "Displayed components can only be changed for 2-point measurements";
  }, [onChangeMeasurementComponentsToDisplay]);

  return (
    <Tooltip
      title={tooltipForToggleMeasurementComponents}
      placement="top"
      PopperProps={{
        sx: {
          zIndex: MAX_ZINDEX_VALUE,
        },
      }}
    >
      <EmbeddedToolbarButton
        aria-label="toggle measurements"
        buttonSize={buttonSize}
        value="Toggle measurements"
        onClick={() => {
          onClick();
        }}
        disabled={!onChangeMeasurementComponentsToDisplay}
      >
        {componentsToDisplay === ComponentsToDisplay.single3d && (
          <Data3dIcon sx={{ color: neutral[0] }} />
        )}
        {componentsToDisplay === ComponentsToDisplay.heightAndHorizontal && (
          <MeasureShowAxesIcon sx={{ color: neutral[0] }} />
        )}
        {componentsToDisplay === ComponentsToDisplay.xyz && (
          <CoordinatesIcon sx={{ color: neutral[0] }} />
        )}
      </EmbeddedToolbarButton>
    </Tooltip>
  );
}
