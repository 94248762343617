import { ProjectTreeActionButton } from "@/components/ui/project-tree-action-button";
import { TreeNode, TreeNodeProps } from "@/components/ui/tree/tree-node";
import { removeEntityTransformOverride } from "@/data-preparation-tool/store/revision-slice";
import { RootState } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  FaroTooltip,
  NoTranslate,
  TruncatedFaroText,
} from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import {
  CaptureTreeEntityRevision,
  isRevisionScanEntity,
} from "@faro-lotv/service-wires";
import { Box, Stack } from "@mui/system";
import { useEffect } from "react";
import {
  setHoveredEntityId,
  unsetHoveredEntityId,
} from "../../store/data-preparation-ui/data-preparation-ui-slice";
import {
  selectHasEntityTransformOverride,
  selectPointCloudStreamForScanEntity,
  selectRevisionEntity,
} from "../../store/revision-selectors";
import { EntityIcon } from "./entity-icon";

/** @returns a node to be shown in the ScanTree */
export function ScanTreeNode({
  node,
  style,
}: TreeNodeProps<CaptureTreeEntityRevision>): JSX.Element {
  const dispatch = useAppDispatch();

  // Unset the global hover state if the component unmounts without a mouse event
  useEffect(() => {
    return () => {
      dispatch(unsetHoveredEntityId(node.id));
    };
  }, [dispatch, node.id]);

  const disabledReason = useAppSelector(selectScanNodeDisabledReason(node.id));
  const isDisabled = !!disabledReason;

  const hasOverride = useAppSelector(selectHasEntityTransformOverride(node.id));

  return (
    <Box component="div" style={style}>
      <FaroTooltip title={disabledReason} placement="right">
        <TreeNode<CaptureTreeEntityRevision>
          node={node}
          shouldExpandNodeOnClick={false}
          shouldDeselectOnClick
          onPointerEnter={() => {
            dispatch(setHoveredEntityId(node.id));
          }}
          onPointerLeave={() => {
            dispatch(unsetHoveredEntityId(node.id));
          }}
          isDisabled={isDisabled}
          isSelectable={!isDisabled}
          style={style}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            minWidth={0}
            width="100%"
            pr={1}
          >
            <EntityIcon entity={node.data} sx={{ fontSize: "1.125rem" }} />
            <TruncatedFaroText
              variant="bodyM"
              color="inherit"
              containerSx={{ flexGrow: 1, width: "100%" }}
            >
              <NoTranslate>{node.data.name}</NoTranslate>
            </TruncatedFaroText>
            {hasOverride && (
              <ProjectTreeActionButton
                name="Reset"
                tooltip="Reset registration edit"
                onClick={() => dispatch(removeEntityTransformOverride(node.id))}
                sx={{
                  // The flex-box item centering doesn't use the correct height, if the button is not a block
                  display: "block",
                }}
              />
            )}
          </Stack>
        </TreeNode>
      </FaroTooltip>
    </Box>
  );
}

/**
 * @returns a reason for disabling a node in the scan tree, or undefined if the nodes should be enabled
 * @param nodeId the id of the entity for the node
 */
function selectScanNodeDisabledReason(nodeId: GUID) {
  return (state: RootState): string | undefined => {
    const nodeEntity = selectRevisionEntity(nodeId)(state);

    if (!nodeEntity) return;

    if (
      isRevisionScanEntity(nodeEntity) &&
      !selectPointCloudStreamForScanEntity(nodeEntity)(state)
    ) {
      return "This point cloud is currently not available";
    }
  };
}
