import {
  CancelAnnotationCreationEventProperties,
  EventType,
  OpenTagManagementProperties,
  SaveAnnotationEventProperties,
} from "@/analytics/analytics-events";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { selectAnnotationAssignableUsers } from "@/store/project-selector";
import { useAppSelector } from "@/store/store-hooks";
import { selectTags } from "@/store/tags/tags-selectors";
import { Tag, UNTAGGED } from "@/store/tags/tags-slice";
import { selectCurrentUser } from "@/store/user-selectors";
import {
  selectMarkupsDisplayForViewers,
  STATUS_OPTIONS,
} from "@faro-lotv/app-component-toolbox";
import {
  AnnotationEditor,
  AnnotationEditorProps,
  AnnotationTypeDropdownProps,
  UserAvatar,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { isEqual } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { TagsManagementDialog } from "../tags/tags-management-dialog";
import { CreateAnnotationFormProps } from "./create-annotation-form";
import { useAnnotationAttachments } from "./use-annotation-attachments";
import { useAnnotationUploadAttachments } from "./use-annotation-upload-attachments";

/**
 * @returns an annotation dialog that is specific to Sphere XG
 */
export function SphereAnnotation({
  children,
  onSave,
  onClose,
  showAnnotationTypeDropdown,
  selectedAnnotationType,
  onAnnotationTypeChange,
  annotationTypeOptions,
}: React.PropsWithChildren<
  CreateAnnotationFormProps &
    AnnotationTypeDropdownProps &
    Pick<AnnotationEditorProps, "showAnnotationTypeDropdown">
>): JSX.Element {
  const [title, setTitle] = useState("Title");
  const [assignee, setAssignee] = useState<string>();
  const [status, setStatus] = useState<string>();
  const [dueDate, setDueDate] = useState<Date>();
  const [isSaving, setIsSaving] = useState(false);
  const [description, setDescription] = useState<string>();
  const [isTagsManagementOpen, setIsTagsManagementOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const currentUser = useAppSelector(selectCurrentUser);
  const allowViewers = useAppSelector(selectMarkupsDisplayForViewers);
  const projectUsers = useAppSelector(
    selectAnnotationAssignableUsers(!!allowViewers),
    isEqual,
  );

  // Create a list with useMemo to avoid recreating one at each frame and recomputing the useAnnotationAttachments hook
  const initialAttachments = useMemo(() => [], []);

  const { attachments, addNewAttachment } = useAnnotationAttachments(
    initialAttachments,
    true,
    "Create",
  );

  const projectTags = useAppSelector(selectTags);
  const tags = useMemo(
    () => projectTags.filter((t) => t.name !== UNTAGGED.name),
    [projectTags],
  );

  const tagsOptions = useMemo(
    () =>
      tags
        .map((tag) => ({
          key: tag.id,
          value: tag.name,
          label: tag.name,
        }))
        .sort((a, b) => a.value.localeCompare(b.value)),
    [tags],
  );

  const { handleErrorWithToast } = useErrorHandlers();
  const { uploadAttachments, progress } = useAnnotationUploadAttachments();

  const createAnnotation = useCallback(() => {
    Analytics.track<SaveAnnotationEventProperties>(EventType.saveAnnotation, {
      isNew: true,
      statusSet: !!status,
      assigneeSet: !!assignee,
      assignToCurrentUser: !!currentUser && assignee === currentUser.id,
      dateSet: !!dueDate,
      descriptionSet: !!description,
      numberOfAttachmentsAdded: attachments.length,
      tagsAdded: selectedTags.length,
      tagsRemoved: 0,
    });

    setIsSaving(true);

    uploadAttachments(attachments)
      .then((newAttachments) =>
        onSave({
          title,
          assignee,
          description,
          dueDate,
          status,
          newAttachments,
          tags: selectedTags,
        }),
      )
      .catch((error) => {
        handleErrorWithToast({ title: "Saving annotation failed", error });
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [
    status,
    assignee,
    currentUser,
    dueDate,
    description,
    attachments,
    uploadAttachments,
    onSave,
    title,
    selectedTags,
    handleErrorWithToast,
  ]);

  return (
    <>
      <AnnotationEditor
        onDescriptionError={(error) =>
          handleErrorWithToast({ title: "Error in description", error })
        }
        progress={progress}
        disabled={isSaving}
        title={title}
        assignee={assignee}
        status={status}
        selectedAnnotationType={selectedAnnotationType}
        attachments={attachments}
        tags={selectedTags}
        addNewAttachment={addNewAttachment}
        onTagsChange={setSelectedTags}
        onTitleChange={setTitle}
        onDescriptionChange={setDescription}
        onAssigneeChange={(e) => setAssignee(e.target.value)}
        onStatusChange={(e) => setStatus(e.target.value)}
        onAnnotationTypeChange={onAnnotationTypeChange}
        onDueDateChange={(e) => setDueDate(e)}
        assigneeOptions={projectUsers.map((user) => ({
          key: user.id ?? user.email,
          value: user.id ?? user.email,
          label: (
            <UserAvatar
              userDisplayInfo={user}
              size="xs"
              shouldShowWhiteRim={false}
              dark
              displayOption="nameAndEmail"
            />
          ),
        }))}
        statusOptions={STATUS_OPTIONS}
        annotationTypeOptions={annotationTypeOptions}
        tagsOptions={tagsOptions}
        confirmButtonText={isSaving ? "Creating" : "Create"}
        shouldShowAttachments
        showAnnotationTypeDropdown={showAnnotationTypeDropdown}
        autoFocusTitle
        onAddNewTagClick={() => {
          Analytics.track<OpenTagManagementProperties>(
            EventType.openTagManagement,
            { via: "annotation" },
          );
          setIsTagsManagementOpen(true);
        }}
        onCancelButtonClick={() => {
          Analytics.track<CancelAnnotationCreationEventProperties>(
            EventType.cancelAnnotationCreation,
            { via: "dialog" },
          );
          onClose();
        }}
        onConfirmButtonClick={createAnnotation}
      >
        {children}
      </AnnotationEditor>
      <TagsManagementDialog
        open={isTagsManagementOpen}
        onClose={() => setIsTagsManagementOpen(false)}
      />
    </>
  );
}
