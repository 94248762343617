import { IElementGenericPointCloudStream } from "@faro-lotv/ielement-types";
import {
  RegistrationRevision,
  RevisionScanEntity,
} from "@faro-lotv/service-wires";
import { DataPreparationStepKey } from "../data-preparation-stepper";
import { InspectAndPublishStatusBanner } from "../inspect-and-publish/inspect-and-publish-status-banner";
import { RegistrationStatusBanner } from "../registration/registration-status-banner";
import { PointCloudsProcessingBanner } from "./point-clouds-processing-banner";

interface DataPreparationBannersProps {
  /** currently active step in the data-preparation page */
  activeStepKey: DataPreparationStepKey;

  /** currently loaded revision */
  revision: RegistrationRevision;

  /** the scan-entities of the data-preparation page */
  scanEntities: RevisionScanEntity[];

  /** the point cloud streams for the scan-entities */
  pointCloudStreams?: IElementGenericPointCloudStream[];
}

/** @returns the banners to show in the data-preparation page */
export function DataPreparationBanners({
  activeStepKey,
  revision,
  scanEntities,
  pointCloudStreams,
}: DataPreparationBannersProps): JSX.Element {
  return (
    <>
      {activeStepKey === DataPreparationStepKey.registration ? (
        <RegistrationStatusBanner revisionState={revision.state} />
      ) : (
        <InspectAndPublishStatusBanner revisionState={revision.state} />
      )}
      <PointCloudsProcessingBanner
        scanEntities={scanEntities}
        pointCloudStreams={pointCloudStreams}
      />
    </>
  );
}
