import { ElementIcon } from "@/components/ui/icons";
import { RootState } from "@/store/store";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import { ArrowDownIcon, FaroText, blue, neutral } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { selectIElement } from "@faro-lotv/project-source";
import { useApiClientContext } from "@faro-lotv/service-wires";
import { ListItemIcon, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";
import { ContextMenuAction } from "./action-types";

type ContextMenuActionItemProps = {
  /** The action to display in the context menu. */
  action: ContextMenuAction;

  /** The identifier of the element which the context menu is for. */
  elementId: GUID;

  /** Callback to execute when the action is clicked. */
  onContextMenuItemClicked(elementId: GUID, action: ContextMenuAction): void;

  /** Callback to execute when the context menu should be closed. */
  closeContextMenu(): void;
};

/**
 * @returns An entry in the context menu for a specific action.
 */
export function ContextMenuActionItem({
  action,
  elementId,
  onContextMenuItemClicked,
  closeContextMenu,
}: ContextMenuActionItemProps): JSX.Element {
  const state = useAppStore().getState();
  const { projectApiClient: projectApi } = useApiClientContext();

  // reference to the menu item to set as anchor
  const menuItemRef = useRef<HTMLLIElement>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const closeMenuAndCleanUp = useCallback(() => {
    setAnchorEl(null);
    closeContextMenu();
  }, [closeContextMenu]);

  const disabledMessage = useAppSelector(
    selectDisabledContextMenuActionMessage(action, elementId),
  );

  const nestedMenuProps = useMemo(
    () =>
      action.nestedMenuProps instanceof Function
        ? action.nestedMenuProps(elementId, state)
        : action.nestedMenuProps,
    [action, elementId, state],
  );

  const shouldShowNestedMenu = useMemo(
    () => nestedMenuProps?.shouldShowNestedMenu?.(elementId, state),
    [nestedMenuProps, elementId, state],
  );

  return (
    <Tooltip title={disabledMessage ?? action.tooltipMessage} placement="right">
      <span>
        <MenuItem
          aria-label={action.label}
          ref={menuItemRef}
          disabled={!!disabledMessage}
          onClick={(ev) => {
            if (shouldShowNestedMenu) {
              setAnchorEl(ev.currentTarget);
              return;
            }
            // Prevent the label from receiving the event and selecting the element.
            ev.stopPropagation();
            onContextMenuItemClicked(elementId, action);
          }}
          sx={{
            mx: 1,
            px: 1,
            color: blue[500],
            ...(anchorEl && { backgroundColor: `${neutral[500]}1A` }),
          }}
        >
          <ListItemIcon
            sx={{
              ...(anchorEl && { color: blue[500] }),
            }}
          >
            <ElementIcon icon={action.icon} />
          </ListItemIcon>
          <FaroText
            variant="bodyM"
            sx={{
              ...(anchorEl && { color: blue[500] }),
            }}
          >
            {action.label}
          </FaroText>
          {shouldShowNestedMenu && (
            <ListItemIcon sx={{ ml: "auto" }}>
              <ArrowDownIcon
                sx={{
                  fontSize: "0.875rem",
                  transform: "rotate(-90deg)",
                  ml: "auto",
                }}
              />
            </ListItemIcon>
          )}
        </MenuItem>

        {/* Sub menu */}
        <Menu
          sx={{ ml: 1.125 }}
          MenuListProps={{
            sx: { p: 1 },
            subheader: nestedMenuProps && (
              <FaroText
                variant="labelS"
                color={neutral[800]}
                sx={{ m: 2, textTransform: "uppercase" }}
              >
                {nestedMenuProps.title}
              </FaroText>
            ),
          }}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={closeMenuAndCleanUp}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {nestedMenuProps?.options.map(
            ({ id, label, icon, tagline, onClick }) => (
              <MenuItem
                onClick={() => {
                  onClick({
                    nestedMenuItemId: id,
                    menuItemId: elementId,
                    state,
                    projectApi,
                  });
                  closeMenuAndCleanUp();
                }}
                key={label}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {icon && (
                  <ListItemIcon
                    sx={{
                      ...(anchorEl && { color: blue[500] }),
                    }}
                  >
                    <ElementIcon icon={icon} />
                  </ListItemIcon>
                )}
                <Stack direction="column">
                  <FaroText variant="labelL">{label}</FaroText>
                  {tagline && (
                    <FaroText variant="helpText" color={neutral[600]}>
                      {tagline}
                    </FaroText>
                  )}
                </Stack>
              </MenuItem>
            ),
          )}
        </Menu>
      </span>
    </Tooltip>
  );
}

/**
 * @param action The action to decide the disabled status for.
 * @param elementId The ID of the element to check the action for.
 * @returns A string if the action should be disabled, to be displayed as tooltip,
 * or `undefined` if the action should be enabled.
 */
function selectDisabledContextMenuActionMessage(
  action: ContextMenuAction,
  elementId: GUID,
) {
  return (state: RootState): string | undefined => {
    const iElement = selectIElement(elementId)(state);

    return iElement && action.disabledMessageForNode?.(iElement, state);
  };
}
