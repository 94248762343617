import { parseImageFromFile, resizeImage, useToast } from "@faro-lotv/flat-ui";
import { assert, getFileExtension } from "@faro-lotv/foundation";
import * as PDFJS from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.min.mjs?url";
import { useCallback, useState } from "react";
import {
  MAX_ALLOWED_IMG_HEIGHT,
  MAX_ALLOWED_IMG_WIDTH,
} from "./create-area-utils";

// Deploy and register the pdfjs worker
PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// Default scale level for the rendered page, higher than 1 to generate a bit crispier images
const DEFAULT_SCALE = 1.5;

// Default to render only the first page for now
const PAGE_TO_RENDER = 1;

export type UseParseUserImageReturn = {
  /** The image file to upload computed by the user selected file */
  parsedImage?: File;

  /** True, if the image has been resized to allowed size */
  hasImgBeenResized: boolean;

  /** Register the file selected by the user */
  parseImage(f?: File): void;
};

/**
 * Provides a local state to capture the user selected file, and convert it to
 * a format that can be uploaded as a floor image to the project
 *
 * @returns an instance of the UseParseUserImageReturn with the data to use in the component
 */
export function useParseUserImage(): UseParseUserImageReturn {
  const [parsedImage, setParsedImage] = useState<File>();
  const [hasImgBeenResized, setHasImgBeenResized] = useState<boolean>(false);
  const { openToast } = useToast();

  const parseImage = useCallback(
    async (input?: File) => {
      // Input will be undefined if the selected image is reset
      if (!input) {
        setParsedImage(undefined);
        return;
      }

      if (getFileExtension(input.name) !== "pdf") {
        const { width, height } = await parseImageFromFile(input);

        if (width > MAX_ALLOWED_IMG_WIDTH || height > MAX_ALLOWED_IMG_HEIGHT) {
          // The user uploaded image has exceeded the maximum allowed size, hence resizing the image
          const compressedFile = await resizeImage(input, {
            maxSize: Math.max(MAX_ALLOWED_IMG_WIDTH, MAX_ALLOWED_IMG_HEIGHT),
          });
          setParsedImage(compressedFile);
          setHasImgBeenResized(true);
        } else {
          setParsedImage(input);
        }
        return;
      }
      convertPdfToImage(input)
        .then(setParsedImage)
        .catch(() => {
          openToast({
            title:
              "Extracting a floor image from the pdf failed, please try with a different file",
            variant: "error",
            persist: false,
          });
        });
    },
    [openToast],
  );

  return {
    parsedImage,
    hasImgBeenResized,
    parseImage,
  };
}

/**
 * Convert the first image of a pdf file to a jpeg file
 *
 * @param input the pdf file selected by the user
 * @returns a File object with the generated jpeg image from the first page of the pdf file
 */
async function convertPdfToImage(input: File): Promise<File> {
  // Read the file content and parse it
  const content = await input.arrayBuffer();
  const pdfDoc = await PDFJS.getDocument(content).promise;
  const firstPage = await pdfDoc.getPage(PAGE_TO_RENDER);

  // Crete a canvas with the same size of the page to render
  const viewport = firstPage.getViewport({ scale: DEFAULT_SCALE });
  const canvas = document.createElement("canvas");
  canvas.height = viewport.height;
  canvas.width = viewport.width;

  const ctx = canvas.getContext("2d");
  assert(
    ctx,
    "Unable to create an offscreen 2d context to render the pdf image",
  );

  // Render and convert the pdf page to a jpeg blob
  await firstPage.render({ canvasContext: ctx, viewport }).promise;
  const blob = await new Promise<Blob>((resolve, reject) =>
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
        return;
      }
      reject(new Error("Unable to convert pdf to image"));
    }, "image/jpeg"),
  );

  return new File([blob], input.name.replace(".pdf", ".jpeg"));
}
