import { SupportedCADFileExtensions } from "@/components/common/point-cloud-file-upload-context/cad-upload-utils";
import {
  SupportedOrbisFileExtensions,
  SupportedPCFileExtensions,
} from "@/components/common/point-cloud-file-upload-context/point-cloud-upload-utils";
import { useAppSelector } from "@/store/store-hooks";
import {
  selectCanUploadCad,
  selectCanUploadOrbisFiles,
  selectCanUploadPointCloud,
} from "@/store/subscriptions/subscriptions-selectors";
import { selectHasWritePermission } from "@/store/user-selectors";
import { UploadIcon, useToast } from "@faro-lotv/app-component-toolbox";
import {
  cyan,
  FaroButton,
  FaroMenu,
  FaroMenuItem,
  FaroMenuSection,
  FaroMenuSeparator,
  FaroText,
  neutral,
  useDialog,
} from "@faro-lotv/flat-ui";
import { getFileExtension } from "@faro-lotv/foundation";
import {
  Box,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@mui/material";
import { Stack } from "@mui/system";
import {
  MouseEventHandler,
  PropsWithChildren,
  RefObject,
  useCallback,
  useRef,
  useState,
} from "react";
import { ListSupportedCadFormats } from "./supported-cad-formats";

/**
 * List of supported pc file extensions with the dots.
 * The dot is required in order to work with the accept property of the file input tag.
 */
const PcFileExtensionsList = Object.values(SupportedPCFileExtensions).map(
  (ext) => `.${ext}`,
);

const OrbisFileExtensionsList = Object.values(SupportedOrbisFileExtensions).map(
  (ext) => `.${ext}`,
);

/**
 * List of CAD file formats to be listed in the File dialog.
 */
const CadFileTypeListForFileInput = [
  SupportedCADFileExtensions.nwd,
  SupportedCADFileExtensions.nwc,
  SupportedCADFileExtensions.rvt,
  SupportedCADFileExtensions.ifc,
  SupportedCADFileExtensions.dwg,
  SupportedCADFileExtensions.dxf,
  SupportedCADFileExtensions.fbx,
];

/**
 * List of CAD file extensions to be listed in the File dialog.
 * We are only using a subset of the supported list as the filter would be too long otherwise.
 * The *.* should allow the user to import ANY file as CAD file anyway.
 * The dot is required in order to work with the accept property of the file input tag.
 */
const CADFileExtensionsListForFileInput = CadFileTypeListForFileInput.map(
  (type: SupportedCADFileExtensions) => `.${type}`,
);

type ImportDataMenuProps = {
  /** Callback to be informed about a new pointcloud file being selected for upload. */
  onPointCloudToUploadSelected?(file: File): void;

  /** Callback to be informed about a new CAD file being selected for upload. */
  onCADToUploadSelected?(file: File): void;

  /** The max supported cloud file size in gigabytes */
  maxCloudFileSizeGB?: number;

  /**
   * If true, the menu will be displayed in dark mode.
   *
   * @default false
   */
  dark?: boolean;

  /** Optional style to apply to the Import button */
  sx?: SxProps<Theme>;
};

/** Possible model types that can be uploaded using the options in the menu */
enum TypeOfFileBeingUploaded {
  pointcloud = "pointcloud",
  cad = "cad",
}

/**
 * structure of arguments to be passed into OnFileChanged
 */
type OnFileChangedArgs = {
  /** file selected in OpenFile dialog */
  selectedFile: File;

  /** file extension */
  extension: string;

  /** callback to be called in case of success of upload */
  onSuccess(): void;

  /** callback to be called in case of failure of upload */
  onFailedUpload(): void;
};

type RecommendedCadModelFileSizesProps = {
  /**
   * If true, the table will be displayed in dark mode.
   */
  dark: boolean;
};

/**
 * Display the list of recommended file sizes for CAD models.
 *
 * @returns A table with the recommended file sizes for CAD models.
 */
function RecommendedCadModelFileSizes({
  dark,
}: RecommendedCadModelFileSizesProps): JSX.Element {
  const color = dark ? neutral[100] : neutral[800];
  const backgroundColor = dark ? neutral[800] : neutral[100];

  interface CadModelSizeInfo {
    nwdFileSize: string;
    rvtFileSize: string;
    expectedExperience: string;
  }
  const fileSizeInfos: CadModelSizeInfo[] = [];
  fileSizeInfos.push({
    nwdFileSize: "Up to 10MB",
    rvtFileSize: "Up to 100MB",
    expectedExperience: "Excellent",
  });
  fileSizeInfos.push({
    nwdFileSize: "10~10MB",
    rvtFileSize: "100~200MB",
    expectedExperience: "Good",
  });
  fileSizeInfos.push({
    nwdFileSize: "20~100MB",
    rvtFileSize: "200MB~1GB",
    expectedExperience: "Average",
  });
  fileSizeInfos.push({
    nwdFileSize: ">100MB",
    rvtFileSize: ">1GB",
    expectedExperience: "Not recommended (yet)",
  });

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color }}>Navisworks file size</TableCell>
            <TableCell sx={{ color }}>Revit file size</TableCell>
            <TableCell sx={{ color }}>Expected experience</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ "& tr:nth-of-type(2n+1)": { backgroundColor } }}>
          {fileSizeInfos.map((fileSizeInfo) => (
            <TableRow key={fileSizeInfo.nwdFileSize}>
              <TableCell sx={{ color }}>{fileSizeInfo.nwdFileSize}</TableCell>
              <TableCell sx={{ color }}>{fileSizeInfo.rvtFileSize}</TableCell>
              <TableCell sx={{ color }}>
                {fileSizeInfo.expectedExperience}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

/**
 * @returns A button that opens a menu to allow the user to chose to import a point cloud, from their PC or from Sphere, or a CAD/BIM.
 */
export function ImportDataMenu({
  onPointCloudToUploadSelected,
  onCADToUploadSelected,
  maxCloudFileSizeGB,
  dark = false,
}: ImportDataMenuProps): JSX.Element | null {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const menuOpen = !!menuAnchor;

  const [typeOfFileToUpload, setTypeOfFileToUpload] = useState<
    TypeOfFileBeingUploaded | undefined
  >();

  const canImportPointCloud = useAppSelector(selectCanUploadPointCloud);
  const canImportOrbisFiles = useAppSelector(selectCanUploadOrbisFiles);
  const canImportCAD = useAppSelector(selectCanUploadCad);

  const onMenuClicked: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event): void => {
      setMenuAnchor(menuOpen ? null : event.currentTarget);
    },
    [menuOpen],
  );
  const onMenuClose = (): void => {
    setMenuAnchor(null);
  };

  const fileInput = useRef<HTMLInputElement>(null);
  const onUploadClicked = (): void => {
    setMenuAnchor(null);
    fileInput.current?.click();
  };

  const { createDialog } = useDialog();

  /**
   * Display a popup dialog with information for user about size file limitations.
   */
  const displayFileSizeRecommendations = useCallback(
    () =>
      createDialog({
        title: "Recommended 3D Model file size",
        variant: "info",
        content: <RecommendedCadModelFileSizes dark={dark} />,
        showCancelButton: false,
        confirmText: "Ok",
        showXButton: true,
        dark,
      }),
    [createDialog, dark],
  );

  /**
   * Will display a popup dialog with the list of supported CAD formats.
   */
  const displayListOfSupportedCadFormats = useCallback(
    () =>
      createDialog({
        title: "Supported 3D model formats",
        variant: "info",
        content: <ListSupportedCadFormats dark={dark} />,
        showCancelButton: false,
        confirmText: "Ok",
        showXButton: true,
        dark,
      }),
    [createDialog, dark],
  );

  const onFileChanged = useCallback(
    ({
      extension,
      selectedFile,
      onFailedUpload,
      onSuccess,
    }: OnFileChangedArgs) => {
      // Use the logic to upload a pc or CAD file based on the extension of the currently selected file
      if (
        typeOfFileToUpload === TypeOfFileBeingUploaded.pointcloud &&
        (PcFileExtensionsList.includes(extension) ||
          OrbisFileExtensionsList.includes(extension))
      ) {
        onPointCloudToUploadSelected?.(selectedFile);
      } else if (typeOfFileToUpload === TypeOfFileBeingUploaded.cad) {
        onCADToUploadSelected?.(selectedFile);
      } else {
        onFailedUpload();
      }
      onSuccess();
    },
    [onCADToUploadSelected, onPointCloudToUploadSelected, typeOfFileToUpload],
  );

  // If the user does not have the PCM add-on, and cannot import CADs,
  // do not show anything.
  if (!canImportPointCloud && !canImportCAD) {
    return null;
  }

  return (
    <ImportBaseComponent onFileChanged={onFileChanged} fileInput={fileInput}>
      <FaroButton aria-label="import 3d data" onClick={onMenuClicked}>
        Import Data
      </FaroButton>

      <FaroMenu
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={onMenuClose}
        sx={{ mt: 1 }}
        dark={dark}
      >
        {canImportPointCloud && (
          <Stack mb={1}>
            <FaroMenuSection dark={dark}>Point Clouds</FaroMenuSection>
            <FaroMenuItem
              label="Upload from your computer"
              Icon={UploadIcon}
              dark={dark}
              onClick={() => {
                setTypeOfFileToUpload(TypeOfFileBeingUploaded.pointcloud);
                if (fileInput.current) {
                  const validExtensions = [];

                  validExtensions.push(...PcFileExtensionsList);

                  if (canImportOrbisFiles) {
                    validExtensions.push(...OrbisFileExtensionsList);
                  }
                  // Update the file input filter with only supported pointcloud extensions
                  Object.assign(fileInput.current, {
                    accept: validExtensions,
                  });
                  onUploadClicked();
                }
              }}
            />
            <FaroText variant="bodyS" color={neutral[600]} mt={1} ml={2} pl={1}>
              {`Maximum file size ${maxCloudFileSizeGB}GB`}
            </FaroText>
          </Stack>
        )}
        {canImportCAD && canImportPointCloud && (
          <FaroMenuSeparator dark={dark} />
        )}
        {canImportCAD && (
          // Wrapping MenuItem and the section header with span instead of fragment
          // as to avoid the console warning for using fragment as child of menu
          <Stack mb={1}>
            <FaroMenuSection dark={dark}>3D MODELS</FaroMenuSection>
            <FaroMenuItem
              label="Upload from your computer"
              Icon={UploadIcon}
              dark={dark}
              onClick={() => {
                setTypeOfFileToUpload(TypeOfFileBeingUploaded.cad);

                if (fileInput.current) {
                  // Update the file input filter with only supported CAD extensions
                  Object.assign(fileInput.current, {
                    accept: CADFileExtensionsListForFileInput,
                  });
                  onUploadClicked();
                }
              }}
            />
            <Stack mt={1} ml={2} pl={1} gap={1}>
              <FaroText
                onClick={displayFileSizeRecommendations}
                color={cyan[400]}
                sx={{
                  ":hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                  },
                }}
                variant="bodyS"
              >
                Recommended file size by format
              </FaroText>
              <FaroText
                onClick={displayListOfSupportedCadFormats}
                color={cyan[400]}
                sx={{
                  ":hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                  },
                }}
                variant="bodyS"
              >
                List of supported formats
              </FaroText>
            </Stack>
          </Stack>
        )}
      </FaroMenu>
    </ImportBaseComponent>
  );
}

/**
 * properties to be passed to ImportBaseComponent from Import3DModelMenu
 */
type ImportBaseComponentProps = {
  /** list of file extensions shown if filter of OpenFile dialog */
  acceptedExtensions?: string;

  /** callback to be executed at the selection of the file in dialog */
  onFileChanged(args: OnFileChangedArgs): void;

  /** ref to file context */
  fileInput: RefObject<HTMLInputElement>;
};

/**
 * @returns component to provide base functionality for Import3DModelMenu
 */
function ImportBaseComponent({
  children,
  acceptedExtensions,
  onFileChanged,
  fileInput,
}: PropsWithChildren<ImportBaseComponentProps>): JSX.Element | null {
  const { openToast } = useToast();

  // if the user has permission to edit the project or not
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  const onFileChangedHandler = useCallback(() => {
    if (fileInput.current) {
      const selectedFile = fileInput.current.files?.[0];
      if (!selectedFile) return;

      const extension =
        `.${getFileExtension(selectedFile.name)}`.toLowerCase() || "";

      onFileChanged({
        selectedFile,
        extension,
        onSuccess: () => {
          // @ts-expect-error this line is needed in chrome to reset the input field
          // or it won't trigger again the onChange even if the user select the same file
          // a second time
          fileInput.current.value = null;
        },
        onFailedUpload: () =>
          openToast({
            title: "Failed to import file",
            message: "The file extension is not supported.",
            variant: "error",
          }),
      });
    }
  }, [fileInput, openToast, onFileChanged]);

  // If the user does not have write permission, do not show anything.
  if (!hasWritePermission) {
    return null;
  }

  return (
    <Box component="div">
      <input
        type="file"
        ref={fileInput}
        accept={acceptedExtensions}
        style={{ display: "none" }}
        onChange={onFileChangedHandler}
      />

      {children}
    </Box>
  );
}
