import { RootState } from "@/store/store";
import { walkWithQueue } from "@faro-lotv/foundation";
import {
  IElement,
  IElementType,
  IElementTypeHint,
  validateKnownIElementTypes,
} from "@faro-lotv/ielement-types";
import {
  newestToOldest,
  selectDataSessionContainedData,
  selectIElementChildren,
} from "@faro-lotv/project-source";
import { alignWizardMode } from "./alignment-wizard/align-wizard-mode";
import { clippingBoxMode } from "./clipping-box-mode/clipping-box-mode";
import { cloudToCadAlignmentMode } from "./cloud-to-cad-alignment-mode/cloud-to-cad-alignment-mode";
import { createAreaMode } from "./create-area-mode/create-area-mode";
import { exportMode } from "./export-mode/export-mode";
import { floorScaleMode } from "./floor-scale-mode/floor-scale-mode";
import { Mode, ModeNames } from "./mode";
import { overviewMode } from "./overview-mode/overview-mode";
import { pathAlignmentMode } from "./path-alignment-mode/path-alignment-mode";
import { projectConversionMode } from "./project-conversion-mode/project-conversion-mode";
import { sheetMode } from "./sheet-mode/sheet-mode";
import { sheetToCadAlignmentMode } from "./sheet-to-cad-alignment-mode/sheet-to-cad-alignment-mode";
import { sheetToCloudAlignmentMode } from "./sheet-to-cloud-alignment-mode/sheet-to-cloud-alignment-mode";
import { splitMode } from "./split-mode/split-mode";
import { startMode } from "./start-mode";
import { walkMode } from "./walk-mode/walk-mode";

/**
 * All the available modes loaded for code splitting;
 */
export const MODES: Record<ModeNames, Mode> = {
  start: startMode,
  sheet: sheetMode,
  overview: overviewMode,
  walk: walkMode,
  split: splitMode,
  clippingbox: clippingBoxMode,
  pathAlignment: pathAlignmentMode,
  floorscale: floorScaleMode,
  createArea: createAreaMode,
  sheetToCadAlignment: sheetToCadAlignmentMode,
  projectConversion: projectConversionMode,
  cloudToCadAlignment: cloudToCadAlignmentMode,
  alignWizard: alignWizardMode,
  sheetToCloudAlignment: sheetToCloudAlignmentMode,
  export: exportMode,
};

/**
 * Load a mode
 *
 * @param name the mode name
 * @returns A promise that will resolve when the mode is loaded
 */
export function getMode(name: ModeNames): Mode {
  return MODES[name];
}

/**
 * Map each type of IElement to the default Mode used to render it
 */
const DEFAULT_MODES_MAP: Partial<
  Record<
    IElementType,
    (el: IElement, state: RootState) => ModeNames | undefined
  >
> = {
  [IElementType.img360]: () => "walk",
  [IElementType.timeSeries]: (el) => {
    switch (el.typeHint) {
      case IElementTypeHint.dataSession:
      case IElementTypeHint.bimModel:
        return "overview";
      case IElementTypeHint.videoRecordings:
        return "sheet";
    }
  },
  [IElementType.section]: (el, state) => {
    if (el.typeHint === IElementTypeHint.dataSession) {
      const { panos, pointClouds } = selectDataSessionContainedData(el)(state);
      if (panos) {
        return "sheet";
      }
      if (pointClouds) {
        return "overview";
      }
    }
  },
  [IElementType.imgSheet]: () => "sheet",
  [IElementType.imgSheetTiled]: () => "sheet",
  [IElementType.model3dStream]: () => "overview",
  [IElementType.pointCloudStreamWebShare]: () => "overview",
  [IElementType.pointCloudStream]: () => "overview",
};

/**
 * Compute the default mode to explore a specific IElement
 *
 * @param targetOrSection The element we want to compute the default mode for
 * @returns The name of the default mode if one is available
 */
export function selectDefaultModeFor(targetOrSection: IElement) {
  return (
    state: RootState,
  ): { targetMode: ModeNames; element: IElement } | undefined => {
    return walkWithQueue<
      IElement | undefined,
      { targetMode: ModeNames; element: IElement } | undefined
    >([targetOrSection], (current, append) => {
      if (!current || !validateKnownIElementTypes(current.type)) return;
      const modeName = DEFAULT_MODES_MAP[current.type]?.(current, state);
      const mode = modeName ? MODES[modeName] : undefined;

      if (
        mode &&
        (!mode.canBeStartedWith || mode.canBeStartedWith(current, state))
      ) {
        return { targetMode: mode.name, element: current };
      }
      const children = selectIElementChildren(current.id)(state);
      children.sort(newestToOldest);
      append(...children);
    });
  };
}
