import { FaroIconButton, ThreeDotsIcon } from "@faro-lotv/flat-ui";
import { Menu } from "@mui/material";
import {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

interface ContextMenuBaseProps {
  /**
   * Whether the context-menu icon should be visible
   *
   * @default true
   */
  visible?: boolean;

  /**
   * Whether the context-menu icon should be disabled
   *
   * @default false
   */
  disabled?: boolean;

  /** Callback executed when the menu is opened/closed */
  onToggle?(open: boolean): void;
}

export interface ContextMenuBaseHandle {
  /** Closes the context menu when called */
  closeContextMenu(): void;
}

/**
 * @returns a three-dots button that opens a context menu, where the menu items are fully customizable as children.
 */
export const ContextMenuBase = forwardRef<
  ContextMenuBaseHandle,
  PropsWithChildren<ContextMenuBaseProps>
>(function ContextMenuBase(
  { visible = true, disabled, children, onToggle },
  ref,
): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = !!anchorEl;

  useEffect(() => {
    onToggle?.(open);
  }, [onToggle, open]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useImperativeHandle(ref, () => ({
    closeContextMenu: handleClose,
  }));

  return (
    <>
      <FaroIconButton
        aria-label="expand sub-menu"
        onClick={handleClick}
        size="xs"
        margin={0.5}
        // Get both the image color and its hover color from the container
        color="inherit"
        hoverColor="inherit"
        // Change the button color to forward the container's color to the image
        sx={{ color: "inherit", visibility: visible ? "visible" : "hidden" }}
        disabled={disabled}
      >
        <ThreeDotsIcon />
      </FaroIconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {children}
      </Menu>
    </>
  );
});
