import { MultiRegistrationReport } from "@/registration-tools/utils/multi-registration-report";
import { FaroButton } from "@faro-lotv/flat-ui";
import { isLoading, MaybeLoading } from "@faro-lotv/foundation";
import { RegistrationRevision } from "@faro-lotv/service-wires";
import { PublishButton } from "./inspect-and-publish-publish-button";

interface InspectAndPublishSidebarButtonsProps {
  /** The revision to publish */
  revision: RegistrationRevision;

  /** The registration report of the active revision. */
  registrationReport: MaybeLoading<MultiRegistrationReport>;

  /** Whether the report is currently open */
  isReportOpen: boolean;

  /** Callback, when the report should be opened/closed */
  onToggleReport(shouldOpen: boolean): void;
}

/** @returns the buttons for the data preparation sidebar in the inspect-and-publish step */
export function InspectAndPublishSidebarButtons({
  revision,
  registrationReport,
  isReportOpen,
  onToggleReport,
}: InspectAndPublishSidebarButtonsProps): JSX.Element {
  return (
    <>
      <FaroButton
        variant="secondary"
        isLoading={isLoading(registrationReport)}
        disabled={!registrationReport}
        onClick={() => onToggleReport(!isReportOpen)}
      >
        {isReportOpen ? "Hide" : "Show"} quality report
      </FaroButton>

      <PublishButton revision={revision} />
    </>
  );
}
