import { Mode } from "@/modes/mode";
import { Features } from "@/store/features/features";
import { selectHasFeature } from "@/store/features/features-slice";
import { changeMode } from "@/store/mode-slice";
import { deactivateTool } from "@/store/ui/ui-slice";
import { ClippingBoxModeDrawer } from "./clipping-box-mode-drawer";
import { ClippingBoxModeOverlay } from "./clipping-box-mode-overlay";
import { ClippingBoxModeScene } from "./clipping-box-mode-scene";
import { ClippingBoxModeTransition } from "./clipping-box-mode-transition";

export const clippingBoxMode: Mode = {
  name: "clippingbox",
  ModeScene: ClippingBoxModeScene,
  ModeOverlay: ClippingBoxModeOverlay,
  ModeTransition: ClippingBoxModeTransition,
  ModeDrawer: ClippingBoxModeDrawer,
  exclusive: {
    title: "Area tool",
    // eslint-disable-next-line require-await -- FIXME
    async onBack({ store, dispatch, createDialog }) {
      const state = store.getState();
      const canCreateArea = selectHasFeature(Features.CreateArea)(state);

      // If the create area workflow is enabled, show a dialog to confirm leaving the tool
      if (canCreateArea) {
        createDialog({
          title: "Leave the tool?",
          content: "Any unsaved change will be lost.",
          size: "s",
          onConfirm: () => {
            dispatch(changeMode("overview"));
            dispatch(deactivateTool());
            return true;
          },
        });
        return;
      }

      dispatch(changeMode("overview"));
      dispatch(deactivateTool());
    },
  },
};
