import { Features, selectHasFeature } from "@/store/features/features-slice";
import { selectProjectIntegrations } from "@/store/integrations/integrations-selectors";
import { selectModeName } from "@/store/mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import {
  AutodeskIcon,
  Option,
  ProcoreIcon,
  SphereXGIcon,
} from "@faro-lotv/flat-ui";
import { BcfServicesIntegrationType } from "@faro-lotv/service-wires";
import { isEqual } from "lodash";
import { PropsWithChildren, useMemo, useState } from "react";
import { AnnotationCreationData } from "./annotation-props";
import {
  AnnotationTypeOption,
  AnnotationTypeOptionHeader,
} from "./annotation-type-option";
import { ExternalAnnotation } from "./external-annotation";
import { getAllBcfServicesIntegrationTypes } from "./external-annotation-utils";
import { ProcoreAnnotation } from "./procore-annotation";
import { SphereAnnotation } from "./sphere-annotation";

export interface CreateAnnotationFormProps {
  /** Callback called when the dialog is closed (successfully or non-successfully). use to clear the annotationToCreate. */
  onClose(): void;

  /** Callback called when the user click the save button */
  onSave(details: AnnotationCreationData): Promise<void>;

  /** If true, it will allow the creation of external annotations if there are some integrations enabled */
  allowExternalMarkups?: boolean;
}

/** The size for the logo to display next of the annotation type option label */
const ANNOTATION_TYPE_LOGO_SIZE = "40px";

/**
  If there are less or equal annotation type options than this minimum number, we will not show the dropdown.
  The value is 1 instead of 0 because the sphere annotation is always available and is already added to the annotation type options
 */
const MINIMUM_ANNOTATION_TYPE_OPTIONS = 1;

/** List of annotation type currently supported */
enum AnnotationOptionType {
  FaroSphereXg = "FARO Sphere XG",
  ProcoreRfi = "Procore RFI",
  ProcoreObservation = "Procore Observation",
  AutodeskAccRfi = "Autodesk ACC RFI",
  AutodeskAccIssue = "Autodesk ACC Issue",
  AutodeskBim360Rfi = "Autodesk BIM360 RFI",
  AutodeskBim360Issue = "Autodesk BIM360 Issue",
}

/**
 * @param value the string value of the AnnotationOptionType enum
 * @returns the AnnotationOptionType enum key corresponding to the given input value string
 */
function getCorrespondingAnnotationType(value: string): AnnotationOptionType {
  switch (value) {
    case AnnotationOptionType.FaroSphereXg:
      return AnnotationOptionType.FaroSphereXg;
    case AnnotationOptionType.ProcoreRfi:
      return AnnotationOptionType.ProcoreRfi;
    case AnnotationOptionType.ProcoreObservation:
      return AnnotationOptionType.ProcoreObservation;
    case AnnotationOptionType.AutodeskAccRfi:
      return AnnotationOptionType.AutodeskAccRfi;
    case AnnotationOptionType.AutodeskAccIssue:
      return AnnotationOptionType.AutodeskAccIssue;
    case AnnotationOptionType.AutodeskBim360Rfi:
      return AnnotationOptionType.AutodeskBim360Rfi;
    case AnnotationOptionType.AutodeskBim360Issue:
      return AnnotationOptionType.AutodeskBim360Issue;
    default:
      throw new Error("Invalid annotation option type value");
  }
}

/** Stores all the dropdown option data for each BCF Services integration type */
const externalAnnotationTypesOptions: Record<
  BcfServicesIntegrationType,
  Option
> = {
  [BcfServicesIntegrationType.procoreRfis]: {
    key: AnnotationOptionType.ProcoreRfi,
    value: AnnotationOptionType.ProcoreRfi,
    label: (
      <AnnotationTypeOption
        label={AnnotationOptionType.ProcoreRfi}
        logo={
          <ProcoreIcon
            aria-label="Logo Procore RFI"
            sx={{ size: ANNOTATION_TYPE_LOGO_SIZE }}
          />
        }
      />
    ),
  },
  [BcfServicesIntegrationType.procoreObservations]: {
    key: AnnotationOptionType.ProcoreObservation,
    value: AnnotationOptionType.ProcoreObservation,
    label: (
      <AnnotationTypeOption
        label={AnnotationOptionType.ProcoreObservation}
        logo={
          <ProcoreIcon
            aria-label="Logo Procore Observation"
            sx={{ size: ANNOTATION_TYPE_LOGO_SIZE }}
          />
        }
      />
    ),
  },
  [BcfServicesIntegrationType.autodeskAccRfis]: {
    key: AnnotationOptionType.AutodeskAccRfi,
    value: AnnotationOptionType.AutodeskAccRfi,
    label: (
      <AnnotationTypeOption
        label={AnnotationOptionType.AutodeskAccRfi}
        logo={
          <AutodeskIcon
            aria-label="Logo Autodesk ACC RFI"
            sx={{ size: ANNOTATION_TYPE_LOGO_SIZE }}
          />
        }
      />
    ),
  },
  [BcfServicesIntegrationType.autodeskAccIssues]: {
    key: AnnotationOptionType.AutodeskAccIssue,
    value: AnnotationOptionType.AutodeskAccIssue,
    label: (
      <AnnotationTypeOption
        label={AnnotationOptionType.AutodeskAccIssue}
        logo={
          <AutodeskIcon
            aria-label="Logo Autodesk ACC Issue"
            sx={{ size: ANNOTATION_TYPE_LOGO_SIZE }}
          />
        }
      />
    ),
  },
  [BcfServicesIntegrationType.autodeskBim360Rfis]: {
    key: AnnotationOptionType.AutodeskBim360Rfi,
    value: AnnotationOptionType.AutodeskBim360Rfi,
    label: (
      <AnnotationTypeOption
        label={AnnotationOptionType.AutodeskBim360Rfi}
        logo={
          <AutodeskIcon
            aria-label="Logo Autodesk BIM360 RFI"
            sx={{ size: ANNOTATION_TYPE_LOGO_SIZE }}
          />
        }
      />
    ),
  },
  [BcfServicesIntegrationType.autodeskBim360Issues]: {
    key: AnnotationOptionType.AutodeskBim360Issue,
    value: AnnotationOptionType.AutodeskBim360Issue,
    label: (
      <AnnotationTypeOption
        label={AnnotationOptionType.AutodeskBim360Issue}
        logo={
          <AutodeskIcon
            aria-label="Logo Autodesk BIM360 issue"
            sx={{ size: ANNOTATION_TYPE_LOGO_SIZE }}
          />
        }
      />
    ),
  },
};

/**
 *
 * @param annotationType The annotation option type
 * @returns The corresponding BCF Services integration type
 */
function getCorrespondingBcfIntegrationType(
  annotationType: AnnotationOptionType,
): BcfServicesIntegrationType {
  for (const [key, value] of Object.entries(externalAnnotationTypesOptions)) {
    if (value.value === annotationType) {
      // This type cast below is safe because of definition of externalAnnotationTypesOptions (Record< BcfServicesIntegrationType, Option>)
      // Using externalAnnotationTypesOptions to find the corresponding BCF Service integration type has the benefit to avoid any
      // modification of this function in the future contrary to a switch case. In addition, the code is shorter and simpler.
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return key as BcfServicesIntegrationType;
    }
  }
  throw new Error("Invalid annotation option type value");
}

/**
 * @returns A form to persist an annotation to the project
 */
export function CreateAnnotationForm({
  children,
  onClose,
  onSave,
  allowExternalMarkups = false,
}: PropsWithChildren<CreateAnnotationFormProps>): JSX.Element {
  const [annotationType, setAnnotationType] = useState(
    AnnotationOptionType.FaroSphereXg,
  );

  const projectIntegrations = useAppSelector(
    selectProjectIntegrations,
    isEqual,
  );

  const annotationTypeOptions: Option[] = useMemo(() => {
    const sphereAnnotation: Option = {
      key: AnnotationOptionType.FaroSphereXg,
      value: AnnotationOptionType.FaroSphereXg,
      label: (
        <AnnotationTypeOption
          label={AnnotationOptionType.FaroSphereXg}
          logo={
            <SphereXGIcon
              aria-label="Logo Sphere XG"
              sx={{ size: ANNOTATION_TYPE_LOGO_SIZE }}
            />
          }
        />
      ),
    };

    const integrationsAnnotations = projectIntegrations
      .map((integration) => getAllBcfServicesIntegrationTypes(integration))
      .flat()
      .map(
        (bcfIntegrationType) =>
          externalAnnotationTypesOptions[bcfIntegrationType],
      );

    const integrationsHeader: Option[] =
      integrationsAnnotations.length > 0
        ? [
            {
              key: "integrationsHeader",
              value: "Integrations",
              label: (
                <AnnotationTypeOptionHeader
                  label="INTEGRATIONS"
                  tooltipText="Below are annotation types of third-party services"
                />
              ),
              isHeader: true,
            },
          ]
        : [];
    return [
      sphereAnnotation,
      ...integrationsHeader,
      ...integrationsAnnotations,
    ];
  }, [projectIntegrations]);

  const externalMarkupCreationEnabled = useAppSelector(
    selectHasFeature(Features.ThirdPartyAnnotation),
  );

  const activeMode = useAppSelector(selectModeName);

  // Right now the external markups are only allowed in walk mode.
  // Some mutation are not yet accepted on the ProjectAPI side for the creation of external markups in 2D Overview.
  // This restriction will be removed in the future by https://faro01.atlassian.net/browse/CADBIM-895
  const enableExternalMarkups =
    externalMarkupCreationEnabled &&
    annotationTypeOptions.length > MINIMUM_ANNOTATION_TYPE_OPTIONS &&
    allowExternalMarkups &&
    activeMode === "walk";

  if (
    !enableExternalMarkups ||
    annotationType === AnnotationOptionType.FaroSphereXg
  ) {
    return (
      <SphereAnnotation
        onClose={onClose}
        onSave={onSave}
        showAnnotationTypeDropdown={enableExternalMarkups}
        selectedAnnotationType={annotationType}
        onAnnotationTypeChange={(value) =>
          setAnnotationType(getCorrespondingAnnotationType(value))
        }
        annotationTypeOptions={annotationTypeOptions}
      >
        {children}
      </SphereAnnotation>
    );
  } else if (
    annotationType === AnnotationOptionType.ProcoreRfi ||
    annotationType === AnnotationOptionType.ProcoreObservation
  ) {
    return (
      <ProcoreAnnotation
        onClose={onClose}
        onSave={onSave}
        selectedAnnotationType={annotationType}
        integrationType={getCorrespondingBcfIntegrationType(annotationType)}
        onAnnotationTypeChange={(value) =>
          setAnnotationType(getCorrespondingAnnotationType(value))
        }
        annotationTypeOptions={annotationTypeOptions}
      />
    );
  }
  return (
    <ExternalAnnotation
      onClose={onClose}
      onSave={onSave}
      selectedAnnotationType={annotationType}
      onAnnotationTypeChange={(value) =>
        setAnnotationType(getCorrespondingAnnotationType(value))
      }
      annotationTypeOptions={annotationTypeOptions}
    />
  );
}
