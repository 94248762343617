import { OrientedBoundingBox } from "@/components/r3f/utils/oriented-bounding-box";
import { selectClippingBox } from "@/store/clipping-box-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { useState } from "react";
import { Plane, Quaternion, Vector3 } from "three";

export type ClippingPlanesReturn = {
  /** The stored clipping planes */
  clippingPlanes: Plane[];
  /** The function to modify the clipping planes */
  setClippingPlanes(p: Plane[]): void;
};

/**
 *
 * @returns an interface to read and write an array of clipping planes to a storage in memory.
 */
export function useClippingPlanes(): ClippingPlanesReturn {
  const clippingBox = useAppSelector(selectClippingBox);

  const [clippingPlanes, setClippingPlanes] = useState(() =>
    obbToPlanes(clippingBox),
  );

  return {
    clippingPlanes,
    setClippingPlanes,
  };
}

function obbToPlanes(box?: OrientedBoundingBox): Plane[] {
  if (!box) {
    return [];
  }

  // Clipping box rotation
  const quat = new Quaternion().fromArray(box.rotation);

  // Main direction axes and their distance from the box origin
  const axes: Array<[Vector3, number]> = [
    [new Vector3(1, 0, 0), box.size[0] / 2],
    [new Vector3(-1, 0, 0), box.size[0] / 2],
    [new Vector3(0, 1, 0), box.size[1] / 2],
    [new Vector3(0, -1, 0), box.size[1] / 2],
    [new Vector3(0, 0, 1), box.size[2] / 2],
    [new Vector3(0, 0, -1), box.size[2] / 2],
  ];

  // Compute plane for each main direction
  return axes.map(([axe, distance]) => {
    // The inverted plane normal, going from the box center to the plane
    const invertedNormal = axe.applyQuaternion(quat);
    // A point on the plane
    const point = new Vector3()
      .fromArray(box.position)
      .add(invertedNormal.clone().multiplyScalar(distance));

    return new Plane().setFromNormalAndCoplanarPoint(
      invertedNormal.negate(),
      point,
    );
  });
}
