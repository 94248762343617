// ESLint gets confused by curried selectors, making it impossible to satisfy all doc-related lints
/* eslint-disable jsdoc/check-param-names */
import { curryAppSelector } from "@/store/reselect";
import { RootState } from "@/store/store";
import { EMPTY_ARRAY, assert, walkWithQueue } from "@faro-lotv/foundation";
import {
  GUID,
  isIElementGenericPointCloudStream,
  isValid,
} from "@faro-lotv/ielement-types";
import {
  CachedWorldTransform,
  DEFAULT_TRANSFORM,
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import {
  CaptureTreeEntityRevision,
  RevisionScanEntity,
  isRevisionScanEntity,
} from "@faro-lotv/service-wires";
import { createSelector } from "@reduxjs/toolkit";
import { Vector3 } from "three";
import { EdgesMap, EntityMap } from "./revision-slice";
import {
  EntityTransformOverrides,
  RevisionTransformCache,
} from "./revision-transform-cache";

/**
 * @param state The current application state.
 * @returns A map from entity ID to the entity definition.
 */
export function selectRevisionEntityMap(state: RootState): EntityMap {
  return state.revision.entityMap;
}

/**
 * @param state The current application state.
 * @returns A map from entity ID to the world transform of the entity.
 */
export function selectRevisionTransformCache(
  state: RootState,
): RevisionTransformCache {
  return state.revision.transformCache;
}

/**
 * @param state The current application state.
 * @returns The map of all captureTree loaded edges.
 */
export function selectEdgesMap(state: RootState): EdgesMap {
  return state.revision.edgesMap;
}

/**
 * @param state The current application state.
 * @returns A map from entity ID to the transform overrides of the entity, e.g. through user edits.
 */
export function selectEntityTransformOverrides(
  state: RootState,
): EntityTransformOverrides {
  return state.revision.transformOverrides;
}

/**
 * @param state The current application state.
 * @returns All loaded entities in the revision.
 */
export const selectRevisionEntities = createSelector(
  [selectRevisionEntityMap],
  // Filtering here is only necessary to make TS happy, due to the index types
  (entityMap) => Object.values(entityMap).filter(isValid),
);

/**
 * @param state The current application state.
 * @returns All loaded point cloud scans in the revision.
 */
export const selectRevisionScans = createSelector(
  [selectRevisionEntities],
  (revisionEntities) => revisionEntities.filter(isRevisionScanEntity),
);

/**
 * @param _state The current application state.
 * @param entityId Id of the revision entity to get the cached world transform for.
 * @returns The cached world transform of the entity.
 */
export const selectRevisionEntityWorldTransformCache = curryAppSelector(
  createSelector(
    [
      selectRevisionTransformCache,
      (_state: RootState, entityId?: GUID) => entityId,
    ],
    getEntityTransformCache,
  ),
);

/**
 * @returns whether an entities transform has been overridden directly (does not account for overrides from ancestors)
 * @param entityId the entity id to check
 */
export function selectHasEntityTransformOverride(entityId: GUID) {
  return (state: RootState) =>
    state.revision.transformOverrides[entityId] !== undefined;
}

/**
 * @returns whether at least one entity transform has been overridden
 * @param state the current application state
 */
export function selectHasSomeEntityTransformOverride(
  state: RootState,
): boolean {
  return !!Object.keys(state.revision.transformOverrides).length;
}

/**
 * @param state The current application state.
 * @param sourceId The ID of the source scan.
 * @param targetId The ID of the target scan.
 * @returns Whether the registration edge is valid, i.e. no related scans have been modified since the registration.
 */
export const selectIsRegistrationEdgeValid = curryAppSelector(
  createSelector(
    [
      selectRevisionEntityMap,
      selectEntityTransformOverrides,
      (state: RootState, sourceId: GUID) => sourceId,
      (state: RootState, sourceId, targetId: GUID) => targetId,
    ],
    (entityMap, transformOverrides, sourceId, targetId) => {
      const sourceAncestorIds = getAncestorIds(entityMap, sourceId);
      const targetAncestorIds = getAncestorIds(entityMap, targetId);

      const sourceScanMoved = sourceAncestorIds.some(
        (id) => !targetAncestorIds.includes(id) && transformOverrides[id],
      );
      const targetScanMoved = targetAncestorIds.some(
        (id) => !sourceAncestorIds.includes(id) && transformOverrides[id],
      );

      return !sourceScanMoved && !targetScanMoved;
    },
  ),
);

/**
 * @param entityMap All loaded entities in the revision.
 * @param entityId The ID of the entity to get the ancestor IDs for.
 * @returns The IDs of all ancestors, including the entity itself.
 */
function getAncestorIds(entityMap: EntityMap, entityId: GUID): GUID[] {
  const ancestorIds = [];
  let currentId: GUID = entityId;

  while (currentId) {
    ancestorIds.push(currentId);

    const entity = entityMap[currentId];
    if (!entity || !entity.parentId) break;

    currentId = entity.parentId;
  }

  return ancestorIds;
}

/**
 * @param scanEntity The scan entity to get the point cloud stream for.
 * @returns The point cloud stream corresponding to the scan entity.
 */
export function selectPointCloudStreamForScanEntity(
  scanEntity?: RevisionScanEntity,
) {
  return (state: RootState) => {
    // The scan entity of the revision has the same ID as the data set IElement in the Capture Tree
    const dataSet = selectIElement(scanEntity?.id)(state);
    return selectChildDepthFirst(
      dataSet,
      isIElementGenericPointCloudStream,
    )(state);
  };
}

/**
 * @param _state The current application state.
 * @param id The ID of the revision entity.
 * @returns The revision entity with the given ID or `undefined` if it's not loaded.
 */
export const selectRevisionEntity = curryAppSelector(
  createSelector(
    [selectRevisionEntityMap, (_state, id?: GUID) => id],
    (entityMap, id) => id && entityMap[id],
  ),
);

/**
 * @returns a cached map of entities to their children
 * @param state the current application state
 */
const selectRevisionEntityChildrenMap = createSelector(
  [(state: RootState) => state.revision.entityMap],
  (entityMap) => {
    const map: Record<GUID, CaptureTreeEntityRevision[] | undefined> = {};

    for (const entity of Object.values(entityMap)) {
      if (!entity?.parentId) continue;

      let childrenOfParent = map[entity.parentId];

      if (!childrenOfParent) {
        childrenOfParent = [];
        map[entity.parentId] = childrenOfParent;
      }

      childrenOfParent.push(entity);
    }

    return map;
  },
);

/**
 * @param id the entity id to get the children for
 * @returns the direct children for an entity
 */
export function selectRevisionEntityChildren(id: GUID) {
  return (state: RootState): CaptureTreeEntityRevision[] =>
    selectRevisionEntityChildrenMap(state)[id] ?? EMPTY_ARRAY;
}

/**
 * @param id of the entity to get the descendants for
 * @returns all descendants of the entity with the given ID
 */
export function selectRevisionEntityAllDescendants(id: GUID) {
  return (state: RootState): CaptureTreeEntityRevision[] => {
    const children = selectRevisionEntityChildren(id)(state);

    const found: CaptureTreeEntityRevision[] = [];

    for (const child of children) {
      found.push(child);
      found.push(...selectRevisionEntityAllDescendants(child.id)(state));
    }

    return found;
  };
}

/**
 * @param state The current application state.
 * @param id The ID of the scan entity
 * @returns The scan entity with the given ID or `undefined` if it's not loaded.
 * @throws an assertion error if the entity is not a valid scan.
 */
export const selectRevisionEntityScan = curryAppSelector(
  createSelector(
    [(state, id: GUID) => selectRevisionEntity(id)(state)],
    (revisionEntity) => {
      if (!revisionEntity) return;

      assert(isRevisionScanEntity(revisionEntity));
      return revisionEntity;
    },
  ),
);

/**
 * @param _state The current application state.
 * @param entityId The ID of the entity to get the scan descendants for.
 * @returns All descendants of the entity which are scans.
 *  The scans are returned in breadth-first order.
 */
export const selectRevisionEntityDescendantScans = curryAppSelector(
  createSelector(
    [
      selectRevisionEntityMap,
      selectRevisionEntityChildrenMap,
      (_state, entityId?: GUID) => entityId,
    ],
    (entityMap, childrenMap, entityId?: GUID) => {
      if (!entityId) return EMPTY_ARRAY;

      const entity = entityMap[entityId];
      if (!entity) return EMPTY_ARRAY;

      const scans: RevisionScanEntity[] = [];

      walkWithQueue([entity], (current, append) => {
        if (isRevisionScanEntity(current)) {
          scans.push(current);
        }

        const children = childrenMap[current.id];
        if (children) append(...children);
      });

      return scans.length ? scans : EMPTY_ARRAY;
    },
  ),
);

/**
 * @param state The current application state.
 * @param entityId The ID of the entity to get the scan center for.
 * @returns The scan center of the entity.
 *  - If the entity is a scan, the center is the position of the scan.
 *  - If the entity is a cluster, the center is the average position of all descendant scans.
 *  - If cluster is empty, the position of the cluster itself is used.
 */
export const selectRevisionEntityScanCenter = curryAppSelector(
  createSelector(
    [
      selectRevisionTransformCache,
      (state, entityId?: GUID) =>
        selectRevisionEntityDescendantScans(entityId)(state),
      (state, entityId?: GUID) => entityId,
    ],
    (transformCache, descendantScans, entityId) => {
      if (!descendantScans.length) {
        return getEntityPosition(transformCache, entityId);
      }

      const center = new Vector3();

      for (const scan of descendantScans) {
        center.add(getEntityPosition(transformCache, scan.id));
      }

      return center.divideScalar(descendantScans.length);
    },
  ),
);

/**
 *
 * @param transformCache The cache of entity world transforms.
 * @param entityId The entity to get the cached transform for.
 * @returns The cached transform for the entity. Defaults to the identity transform.
 */
function getEntityTransformCache(
  transformCache: RevisionTransformCache,
  entityId?: GUID,
): CachedWorldTransform {
  return entityId
    ? transformCache[entityId] ?? DEFAULT_TRANSFORM
    : DEFAULT_TRANSFORM;
}

/**
 * @param transformCache The cache of entity world transforms.
 * @param entityId The entity to get the position for.
 * @returns The position of the entity with the given ID, as a vector.
 */
function getEntityPosition(
  transformCache: RevisionTransformCache,
  entityId?: GUID,
): Vector3 {
  const cachedPosition = getEntityTransformCache(
    transformCache,
    entityId,
  ).position;
  return new Vector3().fromArray(cachedPosition);
}
