import { Banner } from "@faro-lotv/flat-ui";
import { RegistrationState } from "@faro-lotv/service-wires";

type InspectAndPublishStatusProps = {
  /** The current state of the registration. */
  revisionState: RegistrationState;
};

/** @returns A banner showing the current state of the workflow to the user. */
export function InspectAndPublishStatusBanner({
  revisionState,
}: InspectAndPublishStatusProps): JSX.Element | null {
  switch (revisionState) {
    case RegistrationState.merged:
      return (
        <Banner variant="success" title="Dataset published">
          Your data set has been published, you can now view it in the Sphere
          Viewer.
        </Banner>
      );
    case RegistrationState.canceled:
      return (
        <Banner variant="error" title="Registration canceled">
          The registration has been canceled. Please restart the workflow.
        </Banner>
      );
    default:
      // Nothing special, just show the normal view
      return null;
  }
}
