import { RootState } from "@/store/store";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { GUID } from "@faro-lotv/foundation";
import {
  IElement,
  IElementGroup,
  IElementType,
  IElementTypeHint,
  WithHint,
  createIElement,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import {
  IElementWithPose,
  computeReferenceSystemProperties,
  isInsideCaptureTree,
  selectChildDepthFirst,
  selectIElementProjectApiLocalPose,
} from "@faro-lotv/project-source";
import {
  DEFAULT_NODES_GROUP_SCALE_UNIFORM,
  MutationAdd3dNode,
  createMutationAdd3dNode,
} from "@faro-lotv/service-wires";
import { Matrix4, Mesh, Vector3 } from "three";

type CreateModel3dMutationArgs = {
  /** Id of the new IElement that will capture this model */
  modelId: GUID;

  /** Name of the new model */
  name: string;

  /** Target IElement that will contain the new model */
  targetIElement: IElement;

  /** Mesh of the new model */
  model: Mesh;

  /** Current user creating the new model */
  currentUserId: GUID;

  /**
   * World space offset applied to the targetIElement by the viewer compared to its iElement position (in three.js space).
   * Used to calculate the local position of the annotation.
   */
  targetOffset: Matrix4;

  /** Url of the model mesh uploaded to Sphere XG */
  modelUrl: string;

  /** Hash of the uploaded mesh file */
  md5Hash: string;

  /** Size of the uploaded mesh file */
  fileSize: number;

  /** Current application state used to query the project */
  appState: RootState;
};

/**
 *
 * @returns a model3d node mutation that stores the input data
 */
export function createModel3dMutation({
  modelId,
  name,
  targetIElement,
  model,
  currentUserId,
  targetOffset,
  modelUrl,
  md5Hash,
  fileSize,
  appState,
}: CreateModel3dMutationArgs): MutationAdd3dNode {
  const existingNodesGroup = selectChildDepthFirst(targetIElement, (el) =>
    isIElementWithTypeAndHint(el, IElementType.group, IElementTypeHint.nodes),
  )(appState);

  let nodesGroup: IElement;
  let nodesGroupToCreate:
    | WithHint<IElementGroup, IElementTypeHint.nodes>
    | undefined;
  let mutationTarget: GUID;
  let targetTransform: Matrix4;

  if (existingNodesGroup) {
    mutationTarget = existingNodesGroup.id;
    nodesGroup = existingNodesGroup;
    nodesGroupToCreate = undefined;
    targetTransform = selectIElementWorldMatrix4(existingNodesGroup.id)(
      appState,
    );
  } else {
    const insideCaptureTree = isInsideCaptureTree(
      targetIElement,
      appState.iElements.iElements,
    );
    mutationTarget = targetIElement.id;
    nodesGroup = nodesGroupToCreate = createIElement<
      WithHint<IElementGroup, IElementTypeHint.nodes>
    >({
      type: IElementType.group,
      typeHint: IElementTypeHint.nodes,
      xOr: false,
      rootId: targetIElement.rootId,
      name: "Annotations",
      createdBy: currentUserId,
      parentId: targetIElement.id,
      pose: {
        pos: null,
        rot: null,
        scale: {
          x: DEFAULT_NODES_GROUP_SCALE_UNIFORM,
          y: DEFAULT_NODES_GROUP_SCALE_UNIFORM,
          z: DEFAULT_NODES_GROUP_SCALE_UNIFORM,
        },
        isWorldRot: false,
        isWorldPose: false,
        isWorldScale: false,
        gps: null,
        refCoordSystemMatrix: computeReferenceSystemProperties(
          { type: IElementType.group, typeHint: IElementTypeHint.nodes },
          insideCaptureTree,
        ),
      },
    });

    // The targetTransform needs to account for the created group having an additional scale
    targetTransform = selectIElementWorldMatrix4(targetIElement.id)(appState)
      .clone()
      .scale(
        new Vector3(
          DEFAULT_NODES_GROUP_SCALE_UNIFORM,
          DEFAULT_NODES_GROUP_SCALE_UNIFORM,
          DEFAULT_NODES_GROUP_SCALE_UNIFORM,
        ),
      );
  }

  model.updateMatrixWorld();
  const transformLocal = targetTransform
    .clone()
    .premultiply(targetOffset)
    .invert()
    .multiply(model.matrixWorld);

  const modelElement: IElementWithPose = {
    id: modelId,
    typeHint: IElementTypeHint.node,
    type: IElementType.model3d,
    parentId: nodesGroup.id,
  };
  const { pos, rot, scale, refCoordSystemMatrix } =
    selectIElementProjectApiLocalPose(
      modelElement,
      transformLocal,
      existingNodesGroup ? [modelElement] : [modelElement, nodesGroup],
    )(appState);

  return createMutationAdd3dNode(
    mutationTarget,
    {
      uri: modelUrl,
      name,
      md5Hash,
      fileSize,
      fileName: "model.zip",
      id: modelId,
      typeHint: IElementTypeHint.node,
      type: IElementType.model3d,
      descr: null,
      thumbnailUri: null,
      pose: {
        pos,
        rot,
        scale,
        gps: null,
        isWorldRot: false,
        refCoordSystemMatrix,
      },
      rootId: targetIElement.rootId,
      childrenIds: null,
      parentId: nodesGroup.id,
    },
    nodesGroupToCreate,
  );
}
