import { EventType } from "@/analytics/analytics-events";
import { selectBackgroundTask } from "@/store/background-tasks/background-tasks-selector";
import { RootState } from "@/store/store";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import {
  BackgroundTask,
  isFileUploadTask,
  isGenericRegistrationTask,
  isPointCloudExportTask,
  isProcessingTask,
} from "@/utils/background-tasks";
import {
  Checkmark2Icon,
  CloseIcon,
  InProgressIcon,
  InfoIcon,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  BackgroundTaskState,
  ProgressApiSupportedTaskTypes,
  isBackgroundTaskActive,
} from "@faro-lotv/service-wires";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  Divider,
  Popover,
  Stack,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Typography,
} from "@mui/material";
import { isEqual } from "lodash";
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CloudActivityMenuButton } from "./progress-overview-button";
import { TaskCard, ToAlignCard, ToExportCard } from "./progress-overview-card";
import {
  ProcessInfoSource,
  selectProcesses,
} from "./progress-overview-selectors";

/** Available tabs in the ProgressOverview menu */
type MenuTabValues = "import" | "export";

/** @returns a menu to track the upload and processing of PointClouds */
export function ProgressOverviewMenu(): JSX.Element | null {
  const [isOpen, setIsOpen] = useState(false);
  const button = useRef<HTMLButtonElement>(null);
  const [hasNewTasks, setHasNewTasks] = useState(false);
  const [tabValue, setTabValue] = useState<MenuTabValues>("import");

  const { imports, exports, registrations } = useAppSelector(
    selectProcesses,
    isEqual,
  );
  const processes = useMemo(
    () => [...imports, ...exports, ...registrations],
    [imports, exports, registrations],
  );

  // When the list of uploads changes show the badges to notify the user
  // there's new info to check
  useEffect(() => {
    if (!isOpen) {
      setHasNewTasks(processes.length > 0);
    }
    // We don't want this effect to be triggered when "isOpen" changes
    // but only when the list of tracked uploads changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processes]);

  const handleClick = useCallback<MouseEventHandler>(
    (ev) => {
      if (!isOpen) {
        Analytics.track(EventType.openProgressOverview);
        setIsOpen(true);
        setHasNewTasks(false);
        ev.stopPropagation();
      }
    },
    [isOpen],
  );

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: MenuTabValues) => {
      setTabValue(newValue);
    },
    [],
  );

  return (
    <>
      <CloudActivityMenuButton
        ref={button}
        onClick={handleClick}
        processes={processes}
        showBadge={hasNewTasks}
        disabled={processes.length === 0}
      />
      <Popover
        open={isOpen}
        anchorEl={button.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack
          component="div"
          sx={{
            width: "400px",
            maxHeight: "550px",
            borderRadius: "5px",
            p: 0,
            my: 4,
            overflow: "hidden",
          }}
        >
          <Typography fontSize={16} fontWeight={600} mx={4}>
            Cloud activity
          </Typography>
          <TabContext value={tabValue}>
            <Tabs value={tabValue} onChange={handleTabChange} sx={{ mx: 4 }}>
              <Tab label="Import" value="import" aria-label="import tab" />
              <Tab label="Export" value="export" aria-label="export tab" />
              <Tab
                label="Registration"
                value="registration"
                aria-label="registration tab"
              />
            </Tabs>
            <Divider />
            <TabPanel value="import" sx={{ padding: 0, overflow: "scroll" }}>
              <ProgressOverviewTab processes={imports} />
            </TabPanel>
            <TabPanel value="export" sx={{ padding: 0, overflow: "scroll" }}>
              <ExportOverviewTab exports={exports} />
            </TabPanel>
            <TabPanel
              value="registration"
              sx={{ padding: 0, overflow: "scroll" }}
            >
              <ProgressOverviewTab processes={registrations} />
            </TabPanel>
          </TabContext>

          <Stack direction="row" pt={2} spacing={0.5} mx={4}>
            <InfoIcon sx={{ color: "yellow600", height: "0.7em", pt: 0.3 }} />
            <Box component="div" fontSize="0.75em" color="gray700">
              Progress will be lost only if you close the browser while
              uploading or exporting your point cloud.
            </Box>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
}

type ExportOverviewTabProps = {
  exports: ProcessInfoSource[];
};

function ExportOverviewTab({ exports }: ExportOverviewTabProps): JSX.Element {
  return (
    <>
      <Stack component="div" mt={2}>
        {exports.map(({ id }, index) => (
          <Stack component="div" key={index}>
            <ToExportCard taskID={id} />
          </Stack>
        ))}
      </Stack>

      {!!exports.length && <Divider />}
    </>
  );
}

type ProgressOverviewTabProps = {
  processes: ProcessInfoSource[];
};

type InProgressProcess = Array<{
  process: ProcessInfoSource;
  task: BackgroundTask;
}>;

type SortedProcesses = {
  toAlign: ProcessInfoSource[];
  failed: ProcessInfoSource[];
  completed: ProcessInfoSource[];
  inProgress: InProgressProcess;
};

/**
 * @returns Sorted processes, depending on their type and state
 * @param processes The processes to sort
 * @param state Redux root state
 */
function sortProcesses(
  processes: ProcessInfoSource[],
  state: RootState,
): SortedProcesses {
  const toAlign: ProcessInfoSource[] = [];
  const inProgress: InProgressProcess = [];
  const completed: ProcessInfoSource[] = [];
  const failed: ProcessInfoSource[] = [];

  for (const process of processes) {
    switch (process.type) {
      case "ElementToAlignSection":
        toAlign.push(process);
        break;
      case "Task": {
        const task = selectBackgroundTask(process.id)(state);
        assert(
          task,
          "a TaskCard needs to be created on an existing BackgroundTask",
        );
        assert(
          isFileUploadTask(task) ||
            isProcessingTask(task) ||
            isPointCloudExportTask(task) ||
            isGenericRegistrationTask(task),
          "a TaskCard expects a file upload task or a Processing task",
        );
        if (
          task.type !== ProgressApiSupportedTaskTypes.pointCloudExport &&
          isBackgroundTaskActive(task.state)
        ) {
          inProgress.push({ process, task });
        }
        if (
          task.state === BackgroundTaskState.succeeded &&
          isGenericRegistrationTask(task)
        ) {
          completed.push(process);
        }
        if (
          task.type !== ProgressApiSupportedTaskTypes.pointCloudExport &&
          task.state === BackgroundTaskState.failed
        ) {
          failed.push(process);
        }
        break;
      }

      default:
        console.warn(`Type ${process.type} not implemented yet!`);
        break;
    }
  }

  return { toAlign, inProgress, completed, failed };
}

function ProgressOverviewTab({
  processes,
}: ProgressOverviewTabProps): JSX.Element {
  const store = useAppStore();

  const { toAlign, inProgress, completed, failed } = useMemo(
    () => sortProcesses(processes, store.getState()),
    [processes, store],
  );

  const textStyle: SxProps<Theme> = {
    textTransform: "uppercase",
    color: "gray850",
    fontWeight: "bold",
    fontSize: "0.8em",
    mx: 4,
    mt: 2,
  };

  return (
    <>
      {!!toAlign.length && (
        <>
          <Stack direction="row" alignItems="center" sx={textStyle}>
            <Checkmark2Icon sx={{ color: "green700" }} /> Completed &nbsp;
            <Box component="span" sx={{ opacity: "0.6" }}>
              ({toAlign.length})
            </Box>
          </Stack>
          {toAlign.map((process, i) => (
            <ToAlignCard iElementID={process.id} key={i} />
          ))}
          <Divider />
        </>
      )}

      {!!failed.length && (
        <>
          <Stack direction="row" alignItems="center" sx={textStyle}>
            <CloseIcon sx={{ color: "red500" }} /> Failed &nbsp;
            <Box component="span" sx={{ opacity: "0.6" }}>
              ({failed.length})
            </Box>
          </Stack>

          {failed.map(({ id }, i) => (
            <TaskCard taskID={id} key={i} />
          ))}

          <Divider />
        </>
      )}

      {!!completed.length && (
        <>
          <Stack direction="row" alignItems="center" sx={textStyle}>
            <Checkmark2Icon sx={{ color: "green700" }} /> Completed &nbsp;
            <Box component="span" sx={{ opacity: "0.6" }}>
              ({completed.length})
            </Box>
          </Stack>

          {completed.map(({ id }, i) => (
            <TaskCard taskID={id} key={i} />
          ))}

          <Divider />
        </>
      )}

      {!!inProgress.length && (
        <>
          <Stack direction="row" alignItems="center" sx={textStyle}>
            <InProgressIcon sx={{ color: "primary.main" }} /> In Progress &nbsp;
            <Box component="span" sx={{ opacity: "0.6" }}>
              ({inProgress.length})
            </Box>
          </Stack>
          {inProgress.map(({ task }, i) => (
            <TaskCard taskID={task.id} key={i} />
          ))}
        </>
      )}
    </>
  );
}
