import { ElementIcon, useElementIcon } from "@/components/ui/icons";
import {
  AnchorIcon,
  FaroTooltip,
  neutral,
  TruncatedFaroText,
} from "@faro-lotv/app-component-toolbox";
import { IElement } from "@faro-lotv/ielement-types";
import { Grid, SxProps } from "@mui/material";

/** All supported type of labels supported by alignment workflows */
export enum LabelType {
  /** Element which will be moved during alignment */
  alignedElement = "alignedElement",

  /** Element which will not move during alignment */
  referenceElement = "referenceElement",
}

type AlignmentElementLabelProps = {
  /** Element for which label is rendered */
  element: IElement;

  /** Type of icon to render */
  labelType: LabelType;

  /** optional parameter required to provide adjustment in horizontal split screen mode */
  sx?: SxProps;
};

/**
 * @returns control to display name of element in split-screen views for all alignments
 */
export function AlignmentElementLabel({
  element,
  labelType,
  sx,
}: AlignmentElementLabelProps): JSX.Element {
  const elementIcon = useElementIcon(element);

  return (
    <Grid
      item
      container
      alignItems="center"
      sx={{
        paddingX: "3px",
        borderRadius: "30px",
        border: "2px",
        borderColor: neutral[200],
        borderStyle: "solid",
        px: 1,
        position: "absolute",
        height: "auto",
        width: "250px",
        background: neutral[0],
        opacity: 0.8,
        ...sx,
      }}
    >
      <Grid item xs={2}>
        <ElementIcon icon={elementIcon} sx={{ mt: "5px" }} />
      </Grid>
      <Grid item xs={labelType === LabelType.referenceElement ? 8 : 10}>
        <TruncatedFaroText variant="heading14" align="left">
          {element.name}
        </TruncatedFaroText>
      </Grid>
      {labelType === LabelType.referenceElement && (
        <Grid item xs={2} sx={{ mt: "5px" }}>
          <FaroTooltip title="The reference element does not move during the alignment">
            <AnchorIcon htmlColor={neutral[400]} />
          </FaroTooltip>
        </Grid>
      )}
    </Grid>
  );
}
