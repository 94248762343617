import { useErrorHandlers } from "@/errors/components/error-handling-context";
import {
  selectRegistrationAlgorithm,
  selectRegistrationAlgorithmSettings,
} from "@/registration-tools/common/store/registration-parameters/registration-parameters-selectors";
import { Features } from "@/store/features/features";
import { selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import { redirectToDataManagementUrl } from "@/utils/redirects";
import { FaroButton } from "@faro-lotv/flat-ui";
import { GUID, assert } from "@faro-lotv/foundation";
import {
  RegistrationRevision,
  RegistrationState,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback, useState } from "react";

type ConfirmAndRunRefinementButtonProps = {
  /** The currently active revision. */
  revision: RegistrationRevision;

  /** The URL to redirect to after confirming and running the refinement. */
  projectId?: GUID;

  /** The dashboardUrl to redirect to after confirming and running the refinement. */
  dashboardUrl?: string;
};

/** @returns A button to confirm and run a refinement. */
export function ConfirmAndRunRefinementButton({
  revision,
  projectId,
  dashboardUrl,
}: ConfirmAndRunRefinementButtonProps): JSX.Element {
  const [isRefinementRunning, setIsRefinementRunning] = useState(false);

  const { registrationApiClient } = useApiClientContext();
  const { handleErrorWithToast } = useErrorHandlers();

  // It only makes sense to start a new registration if none is in progress/completed yet
  const isDisabled = revision.state !== RegistrationState.started;

  const hasRegistrationDevFeature = useAppSelector(
    selectHasFeature(Features.RegistrationDev),
  );

  const registrationAlgorithm = useAppSelector(selectRegistrationAlgorithm);
  const registrationSettings = useAppSelector(
    selectRegistrationAlgorithmSettings,
  );

  const runRefinement = useCallback(async () => {
    setIsRefinementRunning(true);

    try {
      await registrationApiClient?.startCaptureTreeRegistration({
        revisionId: revision.id,
        registrationAlgorithm: hasRegistrationDevFeature
          ? registrationAlgorithm
          : undefined,
        parameters: hasRegistrationDevFeature
          ? registrationSettings
          : undefined,
      });
      assert(projectId, "Project ID is undefined");

      redirectToDataManagementUrl(projectId, dashboardUrl);
    } catch (error) {
      handleErrorWithToast({
        error,
        title: "Failed to Run Refinement",
      });
    }

    setIsRefinementRunning(false);
  }, [
    dashboardUrl,
    handleErrorWithToast,
    hasRegistrationDevFeature,
    projectId,
    registrationAlgorithm,
    registrationApiClient,
    registrationSettings,
    revision.id,
  ]);

  return (
    <FaroButton
      variant="primary"
      disabled={isDisabled}
      isLoading={isRefinementRunning}
      onClick={runRefinement}
      fullWidth
    >
      Confirm & run refinement
    </FaroButton>
  );
}
