import { Banner } from "@faro-lotv/flat-ui";
import { RegistrationState } from "@faro-lotv/service-wires";

type RegistrationStatusBannerProps = {
  /** The current state of the revision. */
  revisionState: RegistrationState;
};

/** @returns A banner showing the current status of the registration. */
export function RegistrationStatusBanner({
  revisionState,
}: RegistrationStatusBannerProps): JSX.Element {
  switch (revisionState) {
    case RegistrationState.started:
    case RegistrationState.cloudRegistrationStarted:
      return (
        <Banner variant="info" title="In progress">
          Registration is currently in progress. Please return later.
        </Banner>
      );
    case RegistrationState.userModified:
      return (
        <Banner variant="info" title="Editing">
          You can now edit the positioning of scans and clusters.
        </Banner>
      );
    case RegistrationState.registered:
    case RegistrationState.merged:
      return (
        <Banner variant="success" title="Registration completed">
          You can now publish your data set in the next step.
        </Banner>
      );
    case RegistrationState.canceled:
      return (
        <Banner variant="error" title="Registration canceled">
          The registration has been canceled. Please restart the workflow.
        </Banner>
      );
  }
}
