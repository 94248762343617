import { useFileUploadContext } from "@/components/common/file-upload-context/file-uploads-context";
import { useElementFileUploadContext } from "@/components/common/point-cloud-file-upload-context/element-file-upload-context";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { selectBackgroundTasks } from "@/store/background-tasks/background-tasks-selector";
import { changeMode } from "@/store/mode-slice";
import { setActiveElement } from "@/store/selections-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { FileUploadTask, isFileUploadTask } from "@/utils/background-tasks";
import {
  CombinedUploadProgress,
  combinedUploadProgress,
} from "@/utils/combined-upload-progress";
import { useToast } from "@faro-lotv/flat-ui";
import { GUID, removeExtension } from "@faro-lotv/foundation";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  isSupportedImgSheetFileExtension,
  useCreateArea,
  verifyInputName,
} from "./create-area-utils";
import { useDefaultGrid } from "./use-default-grid";
import { useParseUserImage } from "./use-parse-user-image";

type CreateAreaLogic = {
  /** File currently selected */
  file?: File;

  /** Name of the Area and ImgSheet to create */
  inputName: string;

  /** The validation error to show for the input name */
  inputNameError?: string;

  /** Progress of the upload tasks */
  uploadProgress?: CombinedUploadProgress;

  /** True if the default grid is available */
  hasDefaultGrid: boolean;

  /** True, if the image has been resized */
  hasImgBeenResized: boolean;

  /** Set the name of the new area */
  setInputName(name: string): void;

  /** Function that creates a new Area Section, uploads the file and creates a new ImgSheet */
  createSheet(): Promise<void>;

  /** Function that resets the input file and cancels the upload if the user selects so in the dialog */
  cancelUpload(): Promise<void>;

  /** Function to set the current file */
  setFile(file?: File): void;

  /** Function to set the default grid as the current floor image */
  setDefaultGrid(): void;

  /** true if the current file is the default grid */
  isUsingGrid: boolean;
};

/**
 * @returns the internal state and mutations for the create area logic
 */
export function useCreateAreaLogic(): CreateAreaLogic {
  const dispatch = useAppDispatch();

  // Project editing
  const createArea = useCreateArea();

  // File upload and task tracking
  const { uploadManager } = useFileUploadContext();
  const { uploadAreaImageFile } = useElementFileUploadContext();
  const [sectionAreaId, setSectionAreaId] = useState<GUID>();
  const [uploadProgress, setUploadProgress] =
    useState<CombinedUploadProgress>();

  // Ui Helpers
  const { handleErrorWithDialog } = useErrorHandlers();
  const { openToast } = useToast();

  // Floor image state
  const defaultGrid = useDefaultGrid();
  const { parsedImage, hasImgBeenResized, parseImage } = useParseUserImage();
  const [isUsingGrid, setIsUsingGrid] = useState(false);

  // Area name state
  const [inputName, setInputNameInternal] = useState("");
  const [inputNameError, setInputNameError] = useState<string>();

  // Validate area name every time it changes
  const setInputName = useCallback((name: string) => {
    setInputNameInternal(name);
    setInputNameError(verifyInputName(name));
  }, []);

  // Track the task for the file upload of the Sheet Image
  // This will take the first ongoing task available for the element
  // NOTE: this will have to be updated when it will be allowed to add a new Sheet Image to a non empty project
  const tasks = useAppSelector((state) => {
    return selectBackgroundTasks(
      (task): task is FileUploadTask =>
        task.iElementId === sectionAreaId && isFileUploadTask(task),
    )(state);
  }, isEqual);
  const reportedProgress = combinedUploadProgress(tasks);
  useEffect(() => {
    setUploadProgress(reportedProgress);
  }, [reportedProgress]);

  // Reset the workflow to the initial state (no image, no area name)
  const resetToInitialState = useCallback(() => {
    parseImage(undefined);
    setInputName("");
    setInputNameError(undefined);
    setIsUsingGrid(false);
  }, [parseImage, setInputName]);

  // Create a sheet with the current workflow state
  const createSheet = useCallback(async () => {
    if (!parsedImage) {
      openToast({
        title: "Sheet upload failed",
        message: "Please make sure an image is selected",
        variant: "error",
      });
      return;
    }

    const inputNameError = verifyInputName(inputName);
    if (inputNameError) {
      setInputNameError(inputNameError);
      return;
    }

    // Create a Section Area if there is none present in the project
    // The function will return the id of the new area of the already existing area
    const sectionAreaId = await createArea(inputName);

    if (sectionAreaId) {
      // Set the active element to the newly created Section Area
      dispatch(setActiveElement(sectionAreaId));

      setSectionAreaId(sectionAreaId);

      // Show immediately the progress at 0 while we wait for the upload to start
      setUploadProgress({ progress: 0 });
      // Start the upload of the file to the backend
      // After the upload, a mutation to add the ImgSheet will be sent
      // And finally when the uploadManager emits the event onCompleted the passed function will be executed
      uploadAreaImageFile(inputName, sectionAreaId, parsedImage, () =>
        dispatch(changeMode("sheet")),
      );
    }
  }, [
    parsedImage,
    inputName,
    createArea,
    dispatch,
    openToast,
    uploadAreaImageFile,
  ]);

  // Cancel the current file upload and stop the workflow
  // eslint-disable-next-line require-await -- FIXME
  const cancelUpload = useCallback(async () => {
    for (const { id } of tasks) {
      uploadManager.cancelFileUpload(id);
    }
    resetToInitialState();
  }, [resetToInitialState, tasks, uploadManager]);

  // Set a custom floor image file
  const setFile = useCallback(
    (file: File, isGrid: boolean = false) => {
      try {
        setIsUsingGrid(isGrid);

        const fileName = file.name;
        if (isSupportedImgSheetFileExtension(fileName)) {
          parseImage(file);
          if (
            !inputName.length ||
            inputName === removeExtension(parsedImage?.name)
          ) {
            setInputName(removeExtension(fileName));
          }
        } else {
          openToast({
            title: "Failed to import file",
            message: "The file extension is not supported.",
            variant: "error",
          });
        }
      } catch (error) {
        handleErrorWithDialog({ title: "Image sheet upload", error });
      }
    },
    [
      handleErrorWithDialog,
      inputName,
      openToast,
      parseImage,
      parsedImage?.name,
      setInputName,
    ],
  );

  // Load the default grid as the floor image
  const setDefaultGrid = useCallback(() => {
    if (!defaultGrid) return;
    setFile(defaultGrid);
    setInputName(inputName);
    setInputNameError(undefined);
    setIsUsingGrid(true);
  }, [defaultGrid, inputName, setFile, setInputName]);

  return {
    file: parsedImage,
    inputName,
    inputNameError,
    uploadProgress,
    hasDefaultGrid: !!defaultGrid,
    isUsingGrid,
    hasImgBeenResized,
    setFile,
    setInputName,
    cancelUpload,
    createSheet,
    setDefaultGrid,
  };
}
